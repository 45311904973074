import {useContext, createContext, useState, useCallback, useRef, ReactNode} from "react";
import {useMutation} from "react-relay/hooks";
import {UserLoginMutation} from "../users/__generated__/UserLoginMutation.graphql";
import {LoginUserMutation} from "../users/User";
import {TokenStore} from "../../TokenStore";

export interface AuthState {
  loggedIn: boolean;
  accessToken: string;
  expiresIn: 0
}

const initialAuthState:AuthState = {
  loggedIn: false,
  accessToken: "",
  expiresIn: 0
};

export interface AuthContextProps {
  authState: AuthState;
  login: (login:string, password:string) => Promise<LoginResult>;
  logout: () => void;
  isAuthenticating: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

AuthContext.displayName = "AuthContext";

export const useAuth = () => {

  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be within AuthProvider");
  }
  return context;
}

// expires_in z serwera przychodzi w sekundach, dlatego musimy przeliczyć na ms
//const getTimeout = (expiresIn) => (expiresIn/2).toFixed() * 1000;

export interface AuthProviderProps {
  children: ReactNode;
}

export enum LoginResult {
  LoggedIn =  0,
  InvalidCredentials = 1,
  Error = 2
}

// TODO: A co z cookie? To że ja dostanę token, to trochę mało
// bo nie jestem w stanie tego obsłużyć sensownie przy reloadzie strony
// na razie zostanie tak, ale docelowo trzeba będzie to obsłużyć na dedykowanym endpoincie

export const AuthProvider = ({children}: AuthProviderProps) => {

    const [loginUser, isAuthenticating] = useMutation<UserLoginMutation>(LoginUserMutation);
    
    const [authState, setAuthState] = useState<AuthState>(initialAuthState);
    
    //const [isRunning, setRunning] = useState(false);
    const timoutRef = useRef();
    
    /*const start = useCallback( () => {
        setRunning(true);
    },[setRunning]);*/

    const login = useCallback(async (username:string, password:string) => {
      
      return new Promise<LoginResult>((resolve, reject) => {

        loginUser({
          variables: {
            username,
            password
          },
          onCompleted: (data) => {
            const token = data.authenticate?.jwtToken;
            if ( token ) {
              // Save token to global store (relay network layer need to know it)
              TokenStore.token = token; 
              setAuthState({
                accessToken: token,
                loggedIn: true.valueOf(),
                expiresIn: 0
              });
              resolve(LoginResult.LoggedIn);
            } else {
              resolve(LoginResult.InvalidCredentials);
            }
          },
          onError: (err) => {
            reject(err);
          }
        });
        
      });
      
    },[authState]);
    
    const logout = useCallback(
        () => {
            // Unset relay token
            TokenStore.token = "";
            setAuthState(initialAuthState);
            //removeFromStorage();
        },
        [setAuthState],
    );
    
    const stop = useCallback(
        () => {
            if ( typeof timoutRef.current !== "undefined" ) {
                clearTimeout(timoutRef.current);
            }
            
            //setRunning(false);
            
        },
        [/*setRunning,*/ timoutRef],
    );
    
    //const dispatch = useDispatch();

    const refreshAccessToken = useCallback( () => {
               
/*        return axios.post(`${authUrl}`, {
                grant_type: "refresh_token",
                client_id:"app"
            }, {
                withCredentials: true
            }
        ).then(res => {
           
            const newAuthState = {
                loggedIn: true,
                access_token: res.data.access_token,
                expires_in: res.data.expires_in
            };
            
            setAuthState(newAuthState);
            saveToStorage(newAuthState);
            
            if ( isRunning ) {
                timoutRef.current = setTimeout(refreshAccessToken, getTimeout(res.data.expires_in));
            }
            
            return res;
            
        }).catch(err => {
            console.log(err);
            
            setError("sessionExpired");
            setAuthState(initialAuthState);
            removeFromStorage();
            // TODO: No dobra, a co mam zrobić tutaj?
            // Czy to nie powinno być na poziomie aplikacji?
            // Chociaż, może nie...
            // Może lepiej to tutaj ogarnąć od razu w jednym miejscu?             
            dispatch(push("/login"));
            return err;
                
        });*/
        
    },[setAuthState, /*isRunning,*/ /*authUrl,dispatch*/ ]);

    // Sprawdza, czy w localStorage jest zapisana autoryzacja
    // Jeżeli jest to ustawia wewnętrzny stan i  cykliczne odświeżanie tokena
    // Jeżeli nie ma to przekierowuje do okna logowania
/*    useEffect(() => {

        const auth = localStorage.getItem("auth");
        if ( auth ) {
            const authObj = JSON.parse(auth);
            console.log("Zalogowany, pobrane ze storage", authObj);
            setAuthState(authObj);
            //start();
        } else {
            //stop();
            setAuthState(initialAuthState);
            dispatch(push("/login"));
        }

    }, [dispatch]);*/
    
    // Uruchamia cykliczne odświeżanie tokena
/*    useEffect(() => {
        
        if ( authState.expires_in > 0 ) {
            if ( typeof timoutRef.current !== "undefined" ) {
                clearTimeout(timoutRef.current);
            }
            
            const newInterval = getTimeout(authState.expires_in);
            
            if ( isRunning ) {
                timoutRef.current = setTimeout(() => {
                    console.log("Wywołuję z timeouta");
                    refreshAccessToken();
                }, newInterval);
            }
        }
        
        return () => {
            if ( typeof timoutRef.current !== "undefined" ) {
                clearTimeout(timoutRef.current);
            }
        }
        
    },[isRunning, timoutRef, authState, refreshAccessToken]);*/
    
  return <AuthContext.Provider value={{
    authState,
    login,
    isAuthenticating,
    logout
    /*logout,
    isRunning,
    start,
    stop,
    authState,
    refreshAccessToken,
    error,
    setError*/
  }}>{children}</AuthContext.Provider>;
  
};
