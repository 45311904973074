/**
 * @generated SignedSource<<c49ab9021a4fc461685f563a3e4ffafc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateUserInput = {
  clientMutationId?: string | null;
  user: UserInput;
};
export type UserInput = {
  rowId?: number | null;
  accountId: number;
  firstName: string;
  lastName: string;
  displayNameLocal: string;
  displayNameRemote: string;
  deleted?: boolean | null;
  email?: string | null;
  avatar?: string | null;
};
export type UserCreateMutation$variables = {
  data: CreateUserInput;
};
export type UserCreateMutation$data = {
  readonly createUser: {
    readonly userEdge: {
      readonly node: {
        readonly id: string;
        readonly rowId: number;
        readonly firstName: string;
        readonly lastName: string;
        readonly displayNameLocal: string;
        readonly displayNameRemote: string;
        readonly email: string;
        readonly accountByAccountId: {
          readonly id: string;
          readonly rowId: number;
          readonly login: string;
        } | null;
      } | null;
    } | null;
  } | null;
};
export type UserCreateMutation = {
  variables: UserCreateMutation$variables;
  response: UserCreateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "data"
      }
    ],
    "concreteType": "CreateUserPayload",
    "kind": "LinkedField",
    "name": "createUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UsersEdge",
        "kind": "LinkedField",
        "name": "userEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayNameLocal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayNameRemote",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "accountByAccountId",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "login",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCreateMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCreateMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "dea8419766908464b29ca7a0a299d294",
    "id": null,
    "metadata": {},
    "name": "UserCreateMutation",
    "operationKind": "mutation",
    "text": "mutation UserCreateMutation(\n  $data: CreateUserInput!\n) {\n  createUser(input: $data) {\n    userEdge {\n      node {\n        id\n        rowId\n        firstName\n        lastName\n        displayNameLocal\n        displayNameRemote\n        email\n        accountByAccountId {\n          id\n          rowId\n          login\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "25c92428dbecb53fc07d49763ffc2b16";

export default node;
