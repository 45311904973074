import {Box, Table} from "@chakra-ui/react";
import range from "lodash/range"
import {DayRow} from "./DayRow";
import {useTranslation} from "react-i18next";

export const Schedule = () => {
  
  const {t} = useTranslation("schedule");
  
  return (
    <Table variant="unstyled">
      {range(0,7).map( d => <DayRow
        /* @ts-ignore */
        name={t(`days.${d}`)} />)}
    </Table>
  );
  
}