import {
    CacheConfig,
    Environment,
    Network,
    RecordSource,
    RequestParameters,
    Store, UploadableMap,
    Variables
} from 'relay-runtime';
import {fetchGraphQL} from './fetchGraphQL';
import {TokenStore} from "../TokenStore";

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(request: RequestParameters, variables: Variables, cacheConfig: CacheConfig, uploadables?: (UploadableMap | null)) {
  
    return fetchGraphQL(request.text, variables, TokenStore.token);
    
}

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
});