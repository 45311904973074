/**
 * @generated SignedSource<<9b078492e15909ef061a2317a0a56841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useDebouncedLoginValidationQuery$variables = {
  login?: string | null;
};
export type useDebouncedLoginValidationQuery$data = {
  readonly allAccounts: {
    readonly totalCount: number;
  } | null;
};
export type useDebouncedLoginValidationQuery = {
  variables: useDebouncedLoginValidationQuery$variables;
  response: useDebouncedLoginValidationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "login"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "login",
        "variableName": "login"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDebouncedLoginValidationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountsConnection",
        "kind": "LinkedField",
        "name": "allAccounts",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "THROW",
            "path": "allAccounts.totalCount"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDebouncedLoginValidationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountsConnection",
        "kind": "LinkedField",
        "name": "allAccounts",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b6d29430b79accfa1e3d0ca4dc399f6",
    "id": null,
    "metadata": {},
    "name": "useDebouncedLoginValidationQuery",
    "operationKind": "query",
    "text": "query useDebouncedLoginValidationQuery(\n  $login: String\n) {\n  allAccounts(condition: {login: $login}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "85a7e72078cd0255cac4a9ee6361af7e";

export default node;
