/**
 * @generated SignedSource<<582310ee0cf3c402697e299f635648fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserRow$data = {
  readonly id: string;
  readonly accountId: number | null;
  readonly rowId: number;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string | null;
  readonly login: string | null;
  readonly " $fragmentType": "UserRow";
};
export type UserRow$key = {
  readonly " $data"?: UserRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserRow">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserRow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": "firstName",
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "lastName",
      "args": null,
      "kind": "ScalarField",
      "name": "surname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "login",
      "storageKey": null
    }
  ],
  "type": "UsersView",
  "abstractKey": null
};

(node as any).hash = "4def72b6a624322b380361acbf20a1a7";

export default node;
