export async function fetchGraphQL(text:string | null | undefined, variables:any, token:string) {
  
    const headers:Record<string,string> = {       
        'Content-Type': 'application/json'
    }; 
    
    if ( token.length > 0 ) {
      headers["Authorization"] =  `Bearer ${token}`;
    }
  
    const response = await fetch(`${process.env.REACT_APP_GRAPHQL_SERVER_URL}`, {
        method: 'POST',
        headers, 
        body: JSON.stringify({
            query: text,
            variables,
        }),
    });

    // Get the response as JSON
    return await response.json();
}
