import {Flex, Input, InputGroup, InputLeftElement} from "@chakra-ui/react";
import {AddRecordButton} from "./buttons";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

export interface RecordToolboxProps {
  onAddClick?: () => void;
  addVisible?: boolean;
  searchVisible?: boolean;
}

export const RecordToolbox = ({onAddClick, addVisible, searchVisible}:RecordToolboxProps) => {

  const {t} = useTranslation("recordToolbox");
  
  const [search, setSearch] = useState("");
  return (  
    <Flex backgroundColor="white" py={4} pt={0} justifyContent="left">
      {searchVisible && <InputGroup size="sm">
        <InputLeftElement pointerEvents="none" color="gray.500" ><FontAwesomeIcon icon={faSearch} /></InputLeftElement>
        <Input value={search}
                               
                               placeholder={t("searchPlaceholder")}
                               onChange={(evt) => setSearch(evt.currentTarget.value)}
                               width="200px"
      />
      </InputGroup>
        }
      {addVisible && <AddRecordButton onClick={onAddClick} ml="auto"/>}
    </Flex>
  );
  
}