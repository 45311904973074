import {useMutation} from "react-relay/hooks";
import {useCallback} from "react";
import {HardDeleteChatMutation} from "./Chat";
import {ChatDeleteHardMutation} from "./__generated__/ChatDeleteHardMutation.graphql";

export const useChatCrud = (onCompleted?: () => void) => {
  
  const [deleteChat, isDeletingChat] = useMutation<ChatDeleteHardMutation>(HardDeleteChatMutation);
  
  const onDelete = useCallback(
    (id:string) => {
      deleteChat({
        variables: {
            rowId: id,           
        },
        onCompleted: _ => onCompleted?.(),
        
        onError: error => {
          console.log("MUTATION ERRORED", error);
        },
      });
    },
    [deleteChat, onCompleted],
  );
  
  return {
    onDelete,
    isSubmitting: isDeletingChat
  }
}