import React, {Suspense} from 'react';
import './App.css';
import RelayEnvironment from "./relay/RelayEnvironment";
import {loadQuery} from "react-relay/hooks";
import {AppQuery} from "./__generated__/AppQuery.graphql";
import { ChakraProvider } from '@chakra-ui/react'
import "./i18n";
import {adminTheme} from "./adminTheme";
import {graphql} from "babel-plugin-relay/macro";
import {RoutesContainer} from "./components/layout/RoutesContainer";

// Root query
const RootQuery = graphql`
  query AppQuery {
    id
  }
`;

const layoutRef = loadQuery<AppQuery>(RelayEnvironment, RootQuery, {});

function App() {

  //const data = usePreloadedQuery(RootQuery, layoutRef);
  // The <Suspense /> here is a fallback mainly for loading translations
  return (
    <ChakraProvider resetCSS  theme={adminTheme}>
      <Suspense fallback={''}>
        <RoutesContainer />       
      </Suspense>
    </ChakraProvider>
  );
}

export default App;
