import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import type {IconButtonProps} from "@chakra-ui/react";
import {IconButton} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export interface IconDeleteButtonProps extends Omit<IconButtonProps, "icon" | "aria-label"> {
  
}

export const IconDeleteButton = (props: IconDeleteButtonProps) => {

  const {t} = useTranslation("buttons");

  return (
    <IconButton icon={<FontAwesomeIcon icon={faTrash}/>}
                aria-label={t("delete")}
                variant="ghost"
                {...props} />
  );

}