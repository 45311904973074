/**
 * @generated SignedSource<<41ed075eaab27f638f5569e6e2e1c102>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserModalUserQuery$variables = {
  id: number;
};
export type UserModalUserQuery$data = {
  readonly userByRowId: {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly displayNameLocal: string;
    readonly displayNameRemote: string;
    readonly accountByAccountId: {
      readonly id: string;
      readonly rowId: number;
      readonly login: string;
    } | null;
  } | null;
};
export type UserModalUserQuery = {
  variables: UserModalUserQuery$variables;
  response: UserModalUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayNameLocal",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayNameRemote",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "login",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserModalUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByRowId",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "THROW",
            "path": "userByRowId.id"
          },
          {
            "kind": "RequiredField",
            "field": (v3/*: any*/),
            "action": "THROW",
            "path": "userByRowId.firstName"
          },
          {
            "kind": "RequiredField",
            "field": (v4/*: any*/),
            "action": "THROW",
            "path": "userByRowId.lastName"
          },
          {
            "kind": "RequiredField",
            "field": (v5/*: any*/),
            "action": "THROW",
            "path": "userByRowId.displayNameLocal"
          },
          {
            "kind": "RequiredField",
            "field": (v6/*: any*/),
            "action": "THROW",
            "path": "userByRowId.displayNameRemote"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accountByAccountId",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v2/*: any*/),
                "action": "THROW",
                "path": "userByRowId.accountByAccountId.id"
              },
              {
                "kind": "RequiredField",
                "field": (v7/*: any*/),
                "action": "THROW",
                "path": "userByRowId.accountByAccountId.rowId"
              },
              {
                "kind": "RequiredField",
                "field": (v8/*: any*/),
                "action": "THROW",
                "path": "userByRowId.accountByAccountId.login"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserModalUserQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accountByAccountId",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ef46f5d318b46da501896d35828209d",
    "id": null,
    "metadata": {},
    "name": "UserModalUserQuery",
    "operationKind": "query",
    "text": "query UserModalUserQuery(\n  $id: Int!\n) {\n  userByRowId(rowId: $id) {\n    id\n    firstName\n    lastName\n    displayNameLocal\n    displayNameRemote\n    accountByAccountId {\n      id\n      rowId\n      login\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e0711c6313ae15af85faad282a1a1c4";

export default node;
