import {IconButton, Input, InputGroup, InputProps, InputRightElement} from "@chakra-ui/react";
import React, {useRef, useState} from "react";
import {FieldInputProps} from "formik";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

export type PasswordFieldProps = FieldInputProps<any> & InputProps & {
  showHint?: string;
  hideHint?: string;
  eyeTabIndex?: number;
};

export const PasswordField = ({placeholder, showHint, hideHint, value, eyeTabIndex, ...field}:PasswordFieldProps) => {
  
  const [visible, setVisible] = useState(false);
  
  const inputRef = useRef<HTMLInputElement | null>(null);
  
  return (
    <InputGroup size="md">
      <Input ref={inputRef} {...field} id={field.name} value={value} placeholder={placeholder} 
             type={"password"}
             autoComplete="new-password"
             isRequired={false}
      />
      <InputRightElement px={3}>
        <IconButton variant="ghost" 
                    color="blue.600"
                    _hover={{bgColor: "transparent", opacity:"0.7"}}
                    _active={{bColor:"transparent"}}
                    aria-label={showHint ?? ""} 
                    icon={<FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />} onClick={() => {
                      const inp = inputRef.current; 
                      if ( inp ) {
                        const len = value.length;
                        // Tpe must be set here manually
                        inp.type = visible ? "password" : "text";
                        // Mostly for Web Browsers
                        inp.setSelectionRange(len, len);
                        inp.focus();
                        setVisible(!visible);
                      }
                      
                    }}
                    tabIndex={eyeTabIndex}
        />
      </InputRightElement>
    </InputGroup>
  );
  
}