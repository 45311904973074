import {useMutation} from "react-relay/hooks";
//import {ConnectionHandler} from "react-relay";
import {useCallback} from "react";
import {UserData} from "./UserForm";
import {FormikHelpers} from "formik";
import {CreateAccountMutation, CreateUserMutation, DeleteUserMutation, UpdateUserMutation} from "./User";
import {UserCreateAccountMutation} from "./__generated__/UserCreateAccountMutation.graphql";
import {UserCreateMutation} from "./__generated__/UserCreateMutation.graphql";
import {UserUpdateMutation} from "./__generated__/UserUpdateMutation.graphql";
//import {UsersListFilters} from "./useUsersListState";
import {UserDeleteMutation} from "./__generated__/UserDeleteMutation.graphql";

interface UpdateUserData {
  input: {
    id: string,
    userPatch: {
      firstName: string,
      lastName: string,
    }
  },
  account: {
    id: string,
    accountPatch: {
      login: string;
      password?: string;
    }
  }
}

//export const useUserCrud = (filters:UsersListFilters, onCompleted?: () => void) => {
export const useUserCrud = (onCompleted?: () => void) => {
  
  const [createAccount, isCreatingAccount] = useMutation<UserCreateAccountMutation>(CreateAccountMutation);

  const [createUser, isCreatingUser] = useMutation<UserCreateMutation>(CreateUserMutation);
  
  const [updateUser, isUpdatingUser] = useMutation<UserUpdateMutation>(UpdateUserMutation);
  
  const [deleteUser, isDeletingUser] = useMutation<UserDeleteMutation>(DeleteUserMutation);
  
  const onSubmit = useCallback(
    
    (data:UserData, formikHelpers: FormikHelpers<UserData>) => {

      console.log("EEEEEEEEEEE, Coś tam", data.id);
      
      // Update if id exists
      if ( data.id ) {

        const variables:UpdateUserData = {
          input: {
            id: data.id,
            userPatch: {
              firstName: data.firstName,
              lastName: data.lastName,
            }
          },
          account: {
            id: data.accountId,
            accountPatch: {
              login: data.login
            }
          }
        }; 
        
        if ( data.changePassword ) {
          variables.account.accountPatch.password = data.password;
        }
        
        console.log("Update");
        
        // TODO: Co zrobić jeżeli mam update, ale na liście mam widok?
        // może powinienem robić mutację na widoku?
        
        updateUser({
          variables,
          onCompleted: response => {
            onCompleted?.()
          },
          onError: error => {
            console.log("MUTATION ERRORED", error);
          },
          optimisticUpdater: store => {
            //console.log("EEEEEEEEE", store.get(data.nodeId)?.setValue("firstName", data.firstName ?? ""));
          }
        });

      } else { // Create
        
        // TODO: Dodać rekord do listy - optimistic update zrobić
        createAccount({
          variables: {
            data: {
              account: {
                login: data.login,
                typeId: 1,
                organizationId: 1,
                securityRoleId: 1,
                statusCodeSetId: 1,
                defaultStatusCode: 0,
                password: data.password
              }
            }
          },
          
          onCompleted: response => {

            console.log("Account created lets create user");
            const accountId = response?.createAccount?.account?.rowId;
            if ( accountId ) {
              console.log("Id konta", accountId);
              console.log(response);
              
              createUser({
                variables: {
                  data: {
                    user: {
                      accountId,
                      firstName: data.firstName,
                      lastName: data.lastName,
                      displayNameLocal: `${data.firstName} ${data.lastName}`,
                      displayNameRemote: `${data.firstName} ${data.lastName}`
                    }
                  }
                },
                updater: store => {

                  // W sumie, to po chuj to tak updatować
                  // Trzeba wstawić na miejsce, gdzie powinien być user zgodnie z warunkami sortowania
                  // więc trzeba to olać i zrobić relad po prostu
                  
/*                  const createUserField = store.getRootField("createUser");
                  
                  const newUser = createUserField.getLinkedRecord("userEdge");
                  
                    const viewerProxy = store.getRoot();
                    console.log("Viewver proxy", viewerProxy );
                    if ( viewerProxy ) {
                      console.log("FILTERS", filters);
                      const connection = ConnectionHandler.getConnection(viewerProxy, "UsersListQuery_allUserListViews", filters)
                      console.log("Connection", connection );
                      console.log(`id: ${newUser.getLinkedRecord("node").getValue("id")} name: ${newUser.getLinkedRecord("node").getValue("firstName")}, surname ${newUser.getLinkedRecord("node").getValue("lastName")}`);
                      // TODO: Prawdoepoobnie trzeba tutaj będzie zrobić createEdge i zadziała wtedy...
                      if (connection) {
                        const  nU = store.create("sds", "UserListView");
                        nU.setLinkedRecord(newUser.getLinkedRecord("node"), newUser.getLinkedRecord("node").getValue("id"));
                        //const u = ConnectionHandler.createEdge(store, connection, nU,"UserListView");
                        ConnectionHandler.insertEdgeBefore(connection, nU);
                        //ConnectionHandler.insertEdgeBefore(connection, newUser);
                      }
                    }*/
                  
                },
                onError: error =>  {
                  console.log("EEEEEEE", error);
                },
                onCompleted: response => {
                   onCompleted?.();
                }
              })
            }

          }
        })
      }
    },
    [updateUser, createAccount, createUser, onCompleted]
  );
  
  const onDelete = useCallback(
    (id:number) => {
      deleteUser({
        variables: {
          account: {
            rowId: id,
            accountPatch: {
              deleted: true
            }
          }
        },
        onCompleted: response => {
          onCompleted?.()
        },
        onError: error => {
          console.log("MUTATION ERRORED", error);
        },
      });
    },
    [deleteUser, onCompleted],
  );
  
  return {
    onSubmit,
    onDelete,
    isSubmitting: isCreatingAccount || isCreatingUser || isUpdatingUser || isDeletingUser
  }
}