import { Button } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {LegacyRef} from "react";

export interface CloseButtonProps extends ButtonProps {
  innerRef?: LegacyRef<HTMLButtonElement>;
}

export const CloseButton = ({className, innerRef, ...props}:CloseButtonProps) => {
    
    const {t} = useTranslation("buttons");
    return (
        <Button ref={innerRef}
                type="button"
                className={className}
                leftIcon={<FontAwesomeIcon icon={faTimes}/>}
                aria-label={t("close")}
                {...props} >{t("close")}</Button>
    );
};