/**
 * @generated SignedSource<<1126d27ab43e6a8eddebd0dd5cff1c56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAccountByRowIdInput = {
  clientMutationId?: string | null;
  accountPatch: AccountPatch;
  rowId: number;
};
export type AccountPatch = {
  rowId?: number | null;
  typeId?: number | null;
  organizationId?: number | null;
  securityRoleId?: number | null;
  login?: string | null;
  password?: string | null;
  active?: boolean | null;
  deleted?: boolean | null;
  statusCodeSetId?: number | null;
  defaultStatusCode?: number | null;
  maxConcurrentChats?: number | null;
};
export type UserDeleteMutation$variables = {
  account: UpdateAccountByRowIdInput;
};
export type UserDeleteMutation$data = {
  readonly updateAccountByRowId: {
    readonly account: {
      readonly id: string;
    } | null;
  } | null;
};
export type UserDeleteMutation = {
  variables: UserDeleteMutation$variables;
  response: UserDeleteMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "account"
      }
    ],
    "concreteType": "UpdateAccountPayload",
    "kind": "LinkedField",
    "name": "updateAccountByRowId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d5c07144aba2a19b24918ffcbebce623",
    "id": null,
    "metadata": {},
    "name": "UserDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation UserDeleteMutation(\n  $account: UpdateAccountByRowIdInput!\n) {\n  updateAccountByRowId(input: $account) {\n    account {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3e4df22fb5174537f5e55a4b11603ee6";

export default node;
