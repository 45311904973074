import {useCallback, useState} from "react";
import {SortParams} from "./SortableTh";
import {SortDirection} from "../../../types/types";

export interface UseListParamsPameters<FType> {
  onSort?: (orderBy:FType) => void;
  defaultSortBy: string;
  defaultSortDirection?: SortDirection
}

export const useListParams = <FType>({onSort, defaultSortBy, defaultSortDirection = "ASC"}:UseListParamsPameters<FType>) => {

  const [sortParams, setSortParams] = useState<SortParams>({ orderBy: defaultSortBy, direction: defaultSortDirection});

  const sortByDirection = useCallback((orderBy:string) => {

    const newParams:SortParams = { orderBy, direction: sortParams.direction === "ASC" ? "DESC" : "ASC"  };
    onSort?.([`${orderBy}_${newParams.direction}`] as unknown as FType);
    setSortParams(newParams);

  },[sortParams, onSort]);
  
  return {
    sortParams,
    sortByDirection
  }
  
}