import {Alert, Box, Button, Center, Heading, Input, InputGroup, InputLeftAddon, useToast} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState} from "react";
import {LoginResult, useAuth} from "../auth/AuthProvider";
import {useLocation, useNavigate} from "react-router-dom";
import {Cover} from "../common/Cover";
import {useQueries} from "../QueriesProvider";

export interface LoginData {
  username: string;
  password: string;
}

interface LocationState {
  from: Location;
}

export const LoginPage = () => {

  const {t} = useTranslation(["login", "toast"]);

  const [invalid, setInvalid] = useState(false);

  const {login, isAuthenticating} = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const {routeCallbacks: {onChatsRoute}} = useQueries();

  const toast = useToast()

  const from = (location.state as LocationState)?.from?.pathname;

  const usernameRef = useRef<HTMLInputElement>(null);
  const [data, setData] = useState<LoginData>({username: "", password: ""});

  useEffect(() => usernameRef.current?.focus(), []);

  const handleInput = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const newData = {...data, [evt.target.name]: evt.target.value};
    setData(newData);
  }, [data])

  const onSubmit = useCallback(async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setData({...data, password: ""});
    setInvalid(false);
    try {
      const loginResult = await login(data.username, data.password);
      if (loginResult === LoginResult.LoggedIn) {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.

        // TODO: Obsłużyć from, czyli routowanie do adresu, z tórego przyszło żądania logowania
        // Np jeżeli user wszedł neizlogowany na /users, to powinien tam zostać przekierowany
        // Ale to dopiero po wymianie routera, a póki co po zalogowaniu zawsze idziemy do /chats
        /*const to = from === "/" ||
        
        if ( from === "/users" ) {
          onChatsRoute();
        } else {
          onChatsRoute();
        }*/

        // TODO: Fake, żeby nie trzeba było za dużo komentować
        const to = false ? from : "/chats";

        onChatsRoute();

        navigate(to, {replace: true});

      } else {
        setInvalid(true);
      }
    } catch (err) {

      console.error(err);

      toast({
        title: t("toast:errorTitle"),
        description: t("toast:unexpectedError"),
        status: "error",
        duration: null,
        isClosable: true
      });
    }

  }, [data, login, from, t, navigate, toast, onChatsRoute]);

  return (
    <Center minH="100vh" alignItems="start">
      <Box mt="32" minW={{"sm": "450px"}}
           borderWidth={{
             sm: "1px"
           }}
           paddingTop={{
             sm: 8
           }}
           paddingBottom={{
             sm: 10
           }}
           paddingX={{
             base: 4,
             sm: 12
           }}
           borderRadius={4}

           shadow={{sm: "xl"}}
      >
        <Heading as="h1" variant="h1" fontSize="3xl" mb={8} fontWeight="500">{t("login:loginHeader")}</Heading>

        {invalid &&
          <Alert status="error" mb={4} borderRadius={4} variant="solid">{t("login:invalidCredentials")}</Alert>}

        <Box pos="relative">
          <form onSubmit={onSubmit}>
            <InputGroup>
              <InputLeftAddon>
                <FontAwesomeIcon icon={faUser}/>
              </InputLeftAddon>
              <Input name="username" value={data.username} autoComplete="username" onInput={handleInput}
                     placeholder={t("login:usernamePlaceholder")}/>
            </InputGroup>
            <InputGroup mt={4}>
              <InputLeftAddon>
                <FontAwesomeIcon icon={faLock}/>
              </InputLeftAddon>
              <Input type="password" name="password" value={data.password} autoComplete="current-password"
                     onInput={handleInput} placeholder={t("login:passwordPlaceholder")}/>
            </InputGroup>

            <Button type="submit" mt={6} w="100%"
                    colorScheme="blue"
                    disabled={data.username.length === 0 || data.password.length === 0 || isAuthenticating}>{t("login:loginBtn")}</Button>
            {isAuthenticating && <Cover backgroundColor="white"/>}
          </form>
        </Box>
      </Box>
    </Center>
  );
}

export default LoginPage;