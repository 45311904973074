import {ConfirmDeleteModal, ConfirmDeleteModalProps, HeaderFallback} from "../common/modals";
import React, {Suspense, useCallback} from "react";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {Translation, useTranslation} from "react-i18next";
import {Alert, AlertDescription, AlertIcon, Box, Text, useToast, VStack} from "@chakra-ui/react";
import {useQueries} from "../QueriesProvider";
import {useChatCrud} from "./useChatCrud";
import {ChatsListFilters} from "./ChatsList";
import {LightSkeleton} from "../common/LightSkeleton";

export interface ConfirmDeleteChatModalProps extends Pick<ConfirmDeleteModalProps, "onClose"> {
  filters: ChatsListFilters;
}

const BodyFallback = React.memo(() => <VStack align="stretch" spacing={5}>
  <LightSkeleton height={"60px"} />
  <LightSkeleton height={"128px"}  />
</VStack>);

export const ConfirmDeleteChatModal = ({onClose, filters}: ConfirmDeleteChatModalProps) => {
  
  const navigate = useNavigate();
  const toast = useToast()
  const location = useLocation();

  // This translation is used only for toaster, so we can omit suspense
  const {t} = useTranslation("deleteChatModal", {
    useSuspense: false
  });
  
  const {loaders: {chats: {loadChats}}} = useQueries();

  // TODO: Uwspólnić jakoś ustawienia toastera
  const {onDelete, isSubmitting} = useChatCrud(() => {
    toast({
      title: t("chatDeleted"),
      position: "top",
      status: "success",
      duration: 4000,
      isClosable: true
    });
    loadChats(filters, {fetchPolicy: "network-only"});
    navigate("/chats");
  });

  const onConfirm = useCallback(() => {
    const userMatch = matchPath("/chats/delete/:id", location.pathname);
    if (userMatch) {
      const id = userMatch.params.id;
      if (id) {
        onDelete(id);
      }
    }
  }, [location, onDelete]);


  return (
    <ConfirmDeleteModal onConfirm={onConfirm} isOpen={true} onClose={onClose}
                        size="md"
                        header={<Suspense fallback={<HeaderFallback/>}><Translation
                          ns="deleteChatModal">{(t) => t("header")}</Translation></Suspense>}
                        body={<Suspense fallback={<BodyFallback/>}>
                          <Translation ns="deleteChatModal">{(t) =>
                            <Box>
                              <Text textAlign="center" fontSize="xl">
                                {t("content")}
                              </Text>
                              <Alert status="error" mt={5} flexDirection='column'
                                     p={7}
                                     variant="subtle"
                                     borderRadius={8}
                                     alignItems='center'
                                     justifyContent='center'>
                                <AlertIcon boxSize="32px" mr={0} mb={4}/>
                                <AlertDescription maxWidth="sm">
                                  <Text fontSize="xl" textAlign="center">{t("alertContent")}</Text>
                                </AlertDescription>
                              </Alert>
                            </Box>}
                          </Translation>
                        </Suspense>}
                        isSubmitting={isSubmitting}
    />
  );

}