import {graphql} from "babel-plugin-relay/macro";

// CRUD operations for user
export const CreateAccountMutation = graphql`
  mutation UserCreateAccountMutation($data: CreateAccountInput! ) {
    createAccount(input: $data) {
      account {
        id
        rowId
        login
      }
    }
  }
`;

// Może tutaj trzeba pobrac po prostu dane widoku dla danego rekordu, ale..
// musze znac id tego rekordu, jak sie odwołać to tego?

export const CreateUserMutation = graphql`
  mutation UserCreateMutation($data: CreateUserInput! ) {
    createUser(input: $data) {
      userEdge {
        node {
          id
          rowId
          firstName
          lastName
          displayNameLocal
          displayNameRemote
          email
          accountByAccountId {
            id
            rowId
            login
          }
        }         
      }
    }     
  }
`;

export const UpdateUserMutation = graphql`
  mutation UserUpdateMutation($input: UpdateUserInput!, $account: UpdateAccountInput!) {
    updateUser(input: $input) {
      user {
        id
        firstName
        lastName
        displayNameLocal
        displayNameRemote
        email
      }
    }
    updateAccount(input: $account) {
      account {
        login
      }
    }
  }
`;

export const DeleteUserMutation = graphql`
  mutation UserDeleteMutation($account: UpdateAccountByRowIdInput!) {
    updateAccountByRowId(input: $account) {
      account {
        id
      }
    }
  }
`;

export const LoginUserMutation = graphql`
  mutation UserLoginMutation($username: String!, $password: String!) {
    authenticate(input: { email: $username, password: $password}) {
      jwtToken
    }
  }
`;
