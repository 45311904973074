import {UserModalUserQuery$data} from "./__generated__/UserModalUserQuery.graphql";
import {useMemo} from "react";
import {Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {FormikFormGroupTextField} from "../common/formik/FormikFormGroupTextField";
import {useTranslation} from "react-i18next";
import {passwordRepeatSchema, passwordSchema, PasswordValidationI18n} from "../../validation/validation";
import {FormikFormGroupSwitchField} from "../common/formik";
import {Collapse, ScaleFade} from "@chakra-ui/react";
import {LoginField} from "./LoginField";

// Get types for user data from Schema types
// Flatten it
type BaseUserData = Exclude<UserModalUserQuery$data["userByRowId"],null>
type AccountData = Exclude<BaseUserData["accountByAccountId"],null>;
export type UserData = Omit<BaseUserData, "accountByAccountId" | "email"> & Pick<AccountData,"login"> & {
  accountId: AccountData["id"];
  password:string;
  repeatPassword:string;
  changePassword:boolean;
};

const defaultUserData:UserData = {
  id: "",
  firstName: "",
  lastName: "",
  displayNameLocal: "",
  displayNameRemote: "",
  login: "",
  accountId: "",
  password: "",
  repeatPassword: "",
  changePassword: false
}

export interface UserFormProps {
  formInitialValues?: UserData;
  onSubmit: (data: UserData, formikHelpers: FormikHelpers<UserData> ) => void;
  id: string;
}

export const UserForm = ({formInitialValues = defaultUserData, onSubmit, id}:UserFormProps) => {
  
  const {t} = useTranslation(["userForm", "validation"]);
  
  const passwordTranslations:PasswordValidationI18n = useMemo(() => ({
    passwordTooShort: t("validation:passwordTooShort", { signs: "8"}),
    fieldIsRequired: t("validation:fieldRequired"),
    passwordMustContainCapitalLetter: t("validation:passwordMustContainCapitalLetter", {letters: "1"}),
    passwordMustContainSmallLetter: t("validation:passwordMustContainSmallLetter", {letters: "1"}),
    passwordMustContainsDigit: t("validation:passwordMustContainDigit", {digits: "1"}),
    passwordsNotMatch: t("validation:passwordsNotMatch")
  }),[t]);
  
  const validationSchema = useMemo(() => Yup.object({
    changePassword: Yup.boolean(),
    firstName: Yup.string().required(t("validation:fieldRequired")),
    password: Yup.lazy(() => {
      if ( formInitialValues.id ) {
        return Yup.string().when("changePassword", (changePassword) => {
          return changePassword ? passwordSchema(passwordTranslations) : Yup.mixed().notRequired()
        })
      }
      return passwordSchema(passwordTranslations);
    }),
    repeatPassword: Yup.lazy(() => {
      if ( formInitialValues.id ) {
        return Yup.string().when("changePassword", (changePassword) => {
          return changePassword ? passwordRepeatSchema(passwordTranslations, "password") : Yup.mixed().notRequired()
        })
      }
      return passwordRepeatSchema(passwordTranslations, "password");
    }), 
  }),[t, passwordTranslations, formInitialValues]);
  
  return (
    formInitialValues ?
    <Formik initialValues={formInitialValues}
            onSubmit={onSubmit}
            validateOnChange
            validateOnMount={false}
            validationSchema={validationSchema}
    >
      {({handleSubmit, isSubmitting, getFieldProps, values}) => {
        
        return (
          <form id={id}
            onSubmit={(evt) => {
            evt.preventDefault();
            handleSubmit(evt);
          }}>
            <FormikFormGroupTextField name="firstName"
                                      label={t("userForm:firstName")}
                                      placeholder={t("userForm:firstNamePlaceholder")}
                                      helperText={t("userForm:firstNameHelperText")}
                                      isRequired={true} mt={0} />
            <FormikFormGroupTextField name="lastName" 
                                      label={t("userForm:lastName")} 
                                      placeholder={t("userForm:lastNamePlaceholder")}
                                      helperText={t("userForm:lastNameHelperText")}
                                      isRequired={false} />
            
            <LoginField name="login" 
                        label={`${t("userForm:username")} (${t("userForm:email")})`}
                        placeholder={t("userForm:emailPlaceholder")}
                        helperText={t("userForm:emailHelperText")}
                        isRequired={true}
                        autoComplete="username"
            />
            
            {formInitialValues.id && <FormikFormGroupSwitchField label={t("userForm:changePassword")} name="changePassword" />}
            {formInitialValues.id ? <Collapse in={values.changePassword} style={{overflow: values.changePassword ? "visible" : "hidden"}}>
                <ScaleFade in={values.changePassword} initialScale={0.3}>
                  <FormikFormGroupTextField name="password" 
                                            label={t("userForm:password")} 
                                            placeholder={t("userForm:passwordPlaceholder")}
                                            helperText={t("userForm:passwordHelperText")}
                                            isRequired={values.changePassword} type="password" eyeTabIndex={-1} />
                  <FormikFormGroupTextField name="repeatPassword"
                                            label={t("userForm:repeatPassword")}
                                            placeholder={t("userForm:repeatPasswordPlaceholder")}
                                            helperText={t("userForm:repeatPasswordHelperText")}
                                            isRequired={values.changePassword} type="password" eyeTabIndex={-1} />
                </ScaleFade>
              </Collapse> : 
              <>
                <FormikFormGroupTextField name="password" 
                                          label={t("userForm:password")}
                                          placeholder={t("userForm:passwordPlaceholder")}
                                          helperText={t("userForm:passwordHelperText")}
                                          isRequired={true} type="password" eyeTabIndex={-1}/>
                <FormikFormGroupTextField name="repeatPassword" 
                                          label={t("userForm:repeatPassword")} 
                                          placeholder={t("userForm:repeatPasswordPlaceholder")}
                                          helperText={t("userForm:repeatPasswordHelperText")}
                                          isRequired={true} type="password" eyeTabIndex={-1}/>
              </>}
            
        </form>
        );
      }}
    </Formik> : null
  );
  
}