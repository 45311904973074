import {extendTheme, theme} from "@chakra-ui/react";

export const adminTheme = extendTheme({
  components: {
    Heading: {
      variants: {
        h1: {
          color: "#445263"
        }
      }
    },
    Table: {
      variants: {
        list: {
          th: {
            backgroundColor: "gray.50",
          },
          tr: {
            cursor: "pointer",
            _hover: {
              backgroundColor: "blue.50",
            },
            "&:nth-of-type(odd) td": {
                _hover: {
                  backgroundColor: "blue.50",
                },
              }
            
          }
        },
        properties: {
          ...theme.components.Table.variants.unstyled, // This is empty object by default
          th: {
            textTransform: "none",
            fontSize: "var(--chakra-font-size-md)",
            color: "var(--chakra-colors-blackAlpha-700)",
            whiteSpace: "nowrap"
            //backgroundColor: "var(--chakra-colors-gray-100)"
          },
          td: {
            width: "100%",
            paddingInlineStart: "var(--chara-space-2)",
            fontSize: "var(--chakra-font-sizes-md)"
          }
        },
        striped: {
          th: {
            //backgroundColor: "gray.50",
            backgroundColor: "white",
            textTransform: "none",
            fontSize: "var(--chakra-font-size-md)"
          },
          tr: {
            cursor: "pointer",
            _hover: {
              backgroundColor: "blue.50",
            },
            "&:nth-of-type(odd)": {
              _hover: {
                td: {
                  backgroundColor: "blue.50",
                }
              },
            }

          }
        }
      }
    }
  }
});
  