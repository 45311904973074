import {createContext, ReactNode, useCallback, useContext, useEffect} from "react";
import {useQueryLoader} from "react-relay/hooks";
import {UsersListQuery_usersQuery} from "./users/__generated__/UsersListQuery_usersQuery.graphql";
import {UsersListUsersQuery} from "./users/UsersList";
import {UseQueryLoaderLoadQueryOptions} from "react-relay/relay-hooks/useQueryLoader";
import type {DisposeFn, OperationType, VariablesOf} from "relay-runtime";
import type {PreloadedQuery} from "react-relay/relay-hooks/EntryPointTypes";
import {matchPath, useLocation, useNavigationType} from "react-router-dom";
import {UserModalUserQuery} from "./users/__generated__/UserModalUserQuery.graphql";
import {UserQuery} from "./users/UserModal";
import {defaultUsersListState as usersListState} from "./pages";
import {ChatsListQuery_chatsQuery} from "./chats/__generated__/ChatsListQuery_chatsQuery.graphql";
import {ChatsListChatsQuery} from "./chats/ChatsList";
import {ChatHistoryModalChatHistoryQuery} from "./chats/__generated__/ChatHistoryModalChatHistoryQuery.graphql";
import {ChatHistoryQuery} from "./chats/ChatHistoryModal";

export type PreloadedQueryTyped<TQuery extends OperationType> = PreloadedQuery<TQuery> | null | undefined;  

export interface QueriesProviderContextProps {
  routeCallbacks: {
    onUsersRoute: () => void;
    onUserRoute: (id:number) => void;
    
    onChatsRoute: () => void;
    onChatRoute: (id:string) => void;
  },
  loaders: {
    users: {
      loadUsers: (variables: VariablesOf<UsersListQuery_usersQuery>, options?: UseQueryLoaderLoadQueryOptions) => void;
      disposeUsers: DisposeFn;
      usersQueryReference: PreloadedQueryTyped<UsersListQuery_usersQuery>;
      
      loadUser: (variables:VariablesOf<UserModalUserQuery>, options: UseQueryLoaderLoadQueryOptions) => void;
      disposeUser: DisposeFn;
      userQueryReference: PreloadedQueryTyped<UserModalUserQuery>;
      
    },
    
    chats: {
      loadChats: (variables:VariablesOf<ChatsListQuery_chatsQuery>, options?: UseQueryLoaderLoadQueryOptions) => void;
      disposeChats: DisposeFn;
      chatsQueryReference: PreloadedQueryTyped<ChatsListQuery_chatsQuery>;
      
      loadChatHistory: (variables:VariablesOf<ChatHistoryModalChatHistoryQuery>, options?: UseQueryLoaderLoadQueryOptions) => void;
      disposeChatHistory: DisposeFn;
      chatHistoryQueryReference: PreloadedQueryTyped<ChatHistoryModalChatHistoryQuery>;
    }
  }
}

const QueriesProviderContext = createContext<QueriesProviderContextProps | undefined>(undefined);

export const useQueries = () => {
  
  const context = useContext(QueriesProviderContext);
  if (!context) {
    throw new Error("useQueries must be within QueriesProvider");
  }
  
  return context;
  
}

interface QueriesProviderProps {
  children: ReactNode;
}

export const QueriesProvider = ({children}:QueriesProviderProps) => {

  console.log("QueriesProvider:render");
  
  const navigationType = useNavigationType();
  const location = useLocation();
  
  //const [usersListState] = useUsersListState();
  
  const [
    usersQueryReference,
    loadUsers,
    disposeUsers
  ] = useQueryLoader<UsersListQuery_usersQuery>(UsersListUsersQuery);

  const [
    userQueryReference,
    loadUser,
    disposeUser
  ] = useQueryLoader<UserModalUserQuery>(UserQuery);
  
  const [
    chatsQueryReference,
    loadChats,
    disposeChats
  ] = useQueryLoader<ChatsListQuery_chatsQuery>(ChatsListChatsQuery);

  const [
    chatHistoryQueryReference,
    loadChatHistory,
    disposeChatHistory
  ] = useQueryLoader<ChatHistoryModalChatHistoryQuery>(ChatHistoryQuery);

  // TODO: skąd najlepiej brać parametry domyślne?
  // Chyba faktycznie najlepiej jak będą trzymane w redux, bo wtedy będą zapamiętane
  // Ale póki co zrobię na sztywno
  // A reduxa się dołoży
  // A najlepiej to zmienić router na inny
  const onUsersRoute = useCallback(() => {
    
    console.log("QueriesProvider:onUsersRoute. Loading users");
    
    // Albo inaczej.... pobieram z localStorage po prostu
    
    console.log("QueriesProvider:usersLisState", usersListState);
    
    loadUsers({
      first: usersListState.filters.first,
      offset: usersListState.filters.offset,
      orderBy: usersListState.filters.orderBy
    },{
      fetchPolicy: "network-only"
    })
  },[loadUsers]);
  
  const onUserRoute = useCallback(
    (id: number) => {
      console.log("QueriesProvider:onUserRoute. Loading user");
      loadUser({
        id
      }, {
        fetchPolicy: "network-only",
      });
    }, [loadUser]);
  
  const onChatsRoute = useCallback(
    () => {
      console.log("QueriesProvider:onChatsRoute. Loading chats");
      
      loadChats({
          first: 10,
          offset: 0,
          orderBy: ["CREATE_TIME_DESC"]
        },
        {
          fetchPolicy: "network-only"
        })
    }, [loadChats] );

  const onChatRoute = useCallback(
    (id: string) => {
      console.log("QueriesProvider:onUserRoute. Loading user");
      loadChatHistory({
        chatId: id
      }, {
        fetchPolicy: "network-only"
      });
    }, [loadChatHistory]);
  
  useEffect(() => {
    
    // Link pasted in browser or F5
    if ( navigationType === "POP" ) {
      if (matchPath("/users/*", location.pathname)) {

        console.log("QueriesProvider:useEffect POP navigation invoking loadUsers");
        onUsersRoute();
      }
      
      const userMatch = matchPath( "/users/edit/:id", location.pathname);
      if ( userMatch ) {
        console.log("QueriesProvider:useEffect POP navigation invoking loadUser");
        const id = userMatch.params.id;
        console.log("ID", id)
        if (id) {
          onUserRoute(parseInt(id));
        }
      }
      
      if ( matchPath("/chats/*", location.pathname) ) {
        console.log("QueriesProvider:useEffect POP navigation invoking loadChats");
        onChatsRoute();
      }
      
    }
    
    // TODO: Dla kolejnych routes uruchamiamy tutaj odpowiednie zapytania
    
    
  }, [navigationType, onUsersRoute, onUserRoute, onChatsRoute, location]);
  
  return <QueriesProviderContext.Provider value={{
    routeCallbacks: {
      onUsersRoute,
      onUserRoute,
      onChatsRoute,
      onChatRoute
    },
    loaders: {
      users: {
        usersQueryReference,
        loadUsers,
        disposeUsers,
        userQueryReference,
        loadUser,
        disposeUser,
      },
      chats: {
        chatsQueryReference,
        loadChats,
        disposeChats,
        chatHistoryQueryReference,
        disposeChatHistory,
        loadChatHistory
      }
    }
  }}>
    {children}
  </QueriesProviderContext.Provider>
  
}


