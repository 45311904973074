import {ChatContainer, Message, MessageList, MessageGroup, MessageSeparator, MainContainer} from "@chatscope/chat-ui-kit-react";
import {useChat} from "@chatscope/use-chat";
import classNames from "classnames"
import {useEffect} from "react";
import "./ChatMessage.scss";

export const ChatWindow = () => {
  
  const {currentMessages, updateState} = useChat();

  useEffect(() => {
    updateState();
  }, [updateState]);
  
  return (
    <MainContainer style={{border:0}}>
      <ChatContainer>
        <MessageList autoScrollToBottomOnMount={false} autoScrollToBottom={false}>
          {currentMessages?.map(g =>
            <MessageGroup key={g.id}
                          direction={g.direction}
                          className={classNames({"cw-message-group__system": g.messages.length > 0 && g.messages[0].senderId === "SYSTEM"})}
            >
              <MessageGroup.Messages>
                {g.messages
                  .map(m =>
                    m.senderId === "SYSTEM" ? <MessageSeparator key={m.id} className="cw-chat-message-separator">
                      {m.content as unknown as string}
                  </MessageSeparator> : <Message key={m.id}
                                                 type="custom"
                                                 /* @ts-ignore */
                                                 model={{
                                                   direction: g.direction
                                                 }}
                                          className={classNames("cw-chat-message", {"cw-chat-message__system": m.senderId === "SYSTEM"})}
                    >
                      <Message.CustomContent>
                        {m.content as unknown as string}
                      </Message.CustomContent>
                      <Message.Footer
                        /*@ts-ignore*/
                        sentTime={m.time} />
                    </Message>
                    
                  )}
              </MessageGroup.Messages>
            </MessageGroup>)}
        </MessageList>
      </ChatContainer>
    </MainContainer>
  );
}