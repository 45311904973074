import { Button } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

export const SaveButton = ({className, ...props}:ButtonProps) => {
    
    const {t} = useTranslation("buttons");
    
    return (
        <Button
                type="submit"
                className={className}
                colorScheme="blue"
                leftIcon={<FontAwesomeIcon icon={faFloppyDisk}/>}
                aria-label={t("save")}
                {...props} >{t("save")}</Button>
    );
};