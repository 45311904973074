import {Suspense, useMemo, useState} from "react";
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {graphql} from "babel-plugin-relay/macro";
import {Paginator} from "../common/Paginator/Paginator";
import {UsersListBody} from "./UsersListBody";
import {useQueries} from "../QueriesProvider";
import {UsersListFilters} from "./useUsersListState";
import { Cover } from "../common/Cover";
import {RecordListProps} from "../../types/types";
import {SortableTh} from "../common/lists/SortableTh";
import {useListParams} from "../common/lists/useListParams";

/*const fragmentUsersPageUsersQuery = graphql`
  fragment UsersListQuery on Query
  @argumentDefinitions(count: {type: "Int"}, cursor: {type: "Cursor"}) 
  @refetchable(queryName: "UsersListPaginationQuery") {     
    id
    allUsers(first: $count, after: $cursor) @connection(key: "UsersListQuery_allUsers", filters:["first", "after"] ) {
      edges {
        node {
          id
          rowId
          ...UserRow
        }
      }
    }
  }
`;*/

export const UsersListUsersQuery = graphql`
  query UsersListQuery_usersQuery ($first:Int, $after: Cursor, $orderBy:[UsersViewsOrderBy!], $offset: Int ) {
      id
      allUsersViews(first: $first, after: $after, orderBy: $orderBy, offset: $offset ) @connection(key: "UsersListQuery_allUsersViews", filters:["first", "after", "orderBy", "offset"] ) {
          totalCount
          pageInfo
          {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            
          }
          edges {
              cursor
              node {
                  id
                  rowId                 
                  ...UserRow
              }
          }
        
      }
  }
`;

export interface UsersListProps extends RecordListProps<UsersListFilters["orderBy"]>{
  
}      

export const UsersList = ({onNextClick, onPageChange, onSort, isLoading }:UsersListProps) => {
  
  const {t} = useTranslation("usersList");
  const {loaders: {users: {usersQueryReference}}} = useQueries();
  
  const skeletonFallback = useMemo(() => [0,1,2,3,4].map( i => <Tr key={i} height="72px"><Td colSpan={5} /></Tr>),[]);
  
  const [total,setTotal] = useState(0);
  const onTotalChange = (total:number) => setTotal(total);
  
  const {sortParams, sortByDirection} = useListParams<UsersListFilters["orderBy"]>({
    onSort,
    defaultSortBy: "ID",
    defaultSortDirection: "ASC"});
  
  //const [sortParams, setSortParams] = useState<SortParams>({ orderBy: "ID", direction: "ASC"});
  
  /*const sortByDirection = useCallback((orderBy:string) => {
    
    const newParams:SortParams = { orderBy, direction: sortParams.direction === "ASC" ? "DESC" : "ASC"  };
    onSort?.([`${orderBy}_${newParams.direction}`] as unknown as UsersListFilters["orderBy"]);
    setSortParams(newParams);
    
  },[sortParams, onSort]);*/
  
  return (
      <Box>
        <TableContainer position="relative" borderWidth={1} borderBottomWidth={0}>
          <Table variant="striped">
            <Thead>
              <Tr>
                <SortableTh w="100px" fieldName="ID" header="Id" sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading} />
                <SortableTh w="200px" fieldName="NAME" header={t("firstName")} sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading}/>
                <SortableTh w="200px" fieldName="SURNAME" header={t("lastName")} sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading}/>
                <SortableTh fieldName="LOGIN" header={t("username")} sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading}/>
                <Th w="90px">&nbsp;</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Suspense fallback={skeletonFallback}>
                {usersQueryReference && <UsersListBody usersRef={usersQueryReference} onTotalChange={onTotalChange} />}
              </Suspense>
            </Tbody>
          </Table>
          {isLoading && <Cover backgroundColor="white" top="40px" />}
        </TableContainer>
        
        <Box mt={4} px={0} border={1}>
          <Paginator total={total} initialPage={1} initialPageSize={10} onPageChange={onPageChange} />
        </Box>
      </Box>
    );
    
}