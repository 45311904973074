import {Flex, Tr, Th, Td} from "@chakra-ui/react";

export interface DayProps {
  name: string;
}
export const DayRow = ({name}:DayProps) => {
  return (
    <Tr>
      <Th>{name}</Th>
      <Td>
        od - do
      </Td>
    </Tr>
  )
}