import {useTranslation} from "react-i18next";

export const FourHundredAndFourPage = () => {
  
  const {t} = useTranslation("fof");
  
  return (
    <>{t("nothingHere")}</>
  );
}

export default FourHundredAndFourPage;