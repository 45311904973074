import {Suspense} from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Skeleton,
} from "@chakra-ui/react";
import type { ModalProps} from "@chakra-ui/react";
import {graphql} from "babel-plugin-relay/macro";
import {useMemo} from "react";
import {useParams} from "react-router-dom";
import {CloseButton} from "../common/buttons";
import {useTranslation} from "react-i18next";
import range from "lodash/range"
import {ChatHistory} from "./ChatHistory";
import {useQueries} from "../QueriesProvider";

export interface ChatHistoryModalProps extends Pick<ModalProps, "onClose" > {
}

export const ChatHistoryQuery = graphql`
  query ChatHistoryModalChatHistoryQuery($chatId: UUID!) {
    allChatMessageLogs(condition: {chatId: $chatId}) {
        nodes {
          id
          widgetContactByContactId {
            id
            name
          }
          accountByAccountId {
            id
            userByAccountId {
              firstName
              lastName
            }
          }
          content
          messageTime
        }
    }
    chatByRowId(rowId: $chatId) {
      createTime
      endTime
    }
  }`;

export const ChatHistoryModal = ({onClose, /*filters*/ }:ChatHistoryModalProps) => {
  
  const {t} = useTranslation("chatHistoryModal");
  
  const {loaders:{chats:{chatHistoryQueryReference}}} = useQueries();
  
  // Get user id from url
  const { id }  = useParams();
  
  const formSkeletonFallback = useMemo(() => 
    <Box>
      {range(0,10).map( i => {
      return <Skeleton key={i} height={(i - 2) % 3 === 0 ? "20px" : (i - 1) % 3 === 0 ? "40px" : "24px"}
                       mb={i % 3 === 0 && i !== 9 ? "8px" : 0}
                       mt={(i - 2) % 3 === 0 ? "8px" : i !== 0 && i % 3 === 0 ? "12px" : 0}
      ></Skeleton>;
    })}
    </Box>
    
  ,[]); 
  
  return (
    
    <Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false} size="xl" scrollBehavior="inside">
      <ModalOverlay /> 
      <ModalContent sx={{
        height: "calc(100% - 7.5rem)"
      }}>
        <ModalHeader><Suspense>{t("chatHistory")}</Suspense></ModalHeader>
        <ModalCloseButton/>
        <ModalBody position="relative" overflow="hidden">
          <Suspense fallback={formSkeletonFallback}>
            {chatHistoryQueryReference && id && <ChatHistory chatId={id} historyRef={chatHistoryQueryReference} />}
          </Suspense>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <CloseButton onClick={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
  
}