import {PreloadedQuery, usePreloadedQuery} from "react-relay/hooks";
import UserModalUserQueryGraphql, {UserModalUserQuery} from "./__generated__/UserModalUserQuery.graphql";
import {useMemo} from "react";
import {UserData, UserForm, UserFormProps} from "./UserForm";

// TODO: Zgeneralizować wrapper

/*type FC<P = {}> = FunctionComponent<P>;

interface FunctionComponent<P = {}> {
  (props: P, context?: any): ReactElement<any, any> | null;
  propTypes?: WeakValidationMap<P> | undefined;
  contextTypes?: ValidationMap<any> | undefined;
  defaultProps?: Partial<P> | undefined;
  displayName?: string | undefined;
}*/

interface QueryWrapperProps extends Pick<UserFormProps,"onSubmit">{
  //component: React.FC<T extends QRenderer>;
  userRef: PreloadedQuery<UserModalUserQuery>;
  formId: string;
}


export const QueryWrapper = ({userRef, formId, onSubmit}:QueryWrapperProps) => {
  
  const userData = usePreloadedQuery(UserModalUserQueryGraphql, userRef);
  
  const formInitialValues: UserData  | undefined= useMemo( () => {
    
    if ( userData?.userByRowId && userData?.userByRowId?.accountByAccountId ) {
      const u = userData.userByRowId; 
      const a = userData?.userByRowId?.accountByAccountId;
      return {
        id: u.id,
        firstName: u.firstName,
        lastName: u.lastName,
        displayNameRemote: u.displayNameRemote,
        displayNameLocal: u.displayNameLocal,
        login: a.login,
        accountId: a.id,
        password: "",
        repeatPassword: "",
        changePassword: false
      }
    }
  },[userData]);
  
  return (
    <UserForm formInitialValues={formInitialValues} onSubmit={onSubmit} id={formId} />
  );
}
