import {Flex, FlexProps, Spinner} from "@chakra-ui/react";
import {Token} from "@chakra-ui/styled-system/dist/declarations/src/utils";
import * as CSS from "csstype";

export interface CoverProps extends FlexProps {
  backgroundColor?: Token<CSS.Property.Color, "colors">;
}
export const Cover = ({backgroundColor, ...props}:CoverProps) => {
  return (
    <Flex opacity="0.6" backgroundColor={backgroundColor ?? "blue.50"} position="absolute" top={0} left={0} right={0} bottom={0}
          justifyContent="center" 
          alignItems="center"
          {...props}
    >
      <Spinner />
    </Flex>
  );
};