/**
 * @generated SignedSource<<4d16e286820edace8b5d2608bc159f6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsersViewsOrderBy = "NATURAL" | "ID_ASC" | "ID_DESC" | "ACCOUNT_ID_ASC" | "ACCOUNT_ID_DESC" | "NAME_ASC" | "NAME_DESC" | "SURNAME_ASC" | "SURNAME_DESC" | "DISPLAY_NAME_LOCAL_ASC" | "DISPLAY_NAME_LOCAL_DESC" | "DISPLAY_NAME_REMOTE_ASC" | "DISPLAY_NAME_REMOTE_DESC" | "EMAIL_ASC" | "EMAIL_DESC" | "ACTIVE_ASC" | "ACTIVE_DESC" | "DEFAULT_STATUS_CODE_ASC" | "DEFAULT_STATUS_CODE_DESC" | "LOGIN_ASC" | "LOGIN_DESC" | "MAX_CONCURRENT_CHATS_ASC" | "MAX_CONCURRENT_CHATS_DESC" | "ORGANIZATION_ID_ASC" | "ORGANIZATION_ID_DESC" | "SECURITY_ROLE_ID_ASC" | "SECURITY_ROLE_ID_DESC" | "STATUS_CODE_SET_ID_ASC" | "STATUS_CODE_SET_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type UsersListQuery_usersQuery$variables = {
  first?: number | null;
  after?: any | null;
  orderBy?: ReadonlyArray<UsersViewsOrderBy> | null;
  offset?: number | null;
};
export type UsersListQuery_usersQuery$data = {
  readonly id: string;
  readonly allUsersViews: {
    readonly totalCount: number;
    readonly pageInfo: {
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: any | null;
      readonly endCursor: any | null;
    };
    readonly edges: ReadonlyArray<{
      readonly cursor: any | null;
      readonly node: {
        readonly id: string;
        readonly rowId: number;
        readonly " $fragmentSpreads": FragmentRefs<"UserRow">;
      } | null;
    }>;
  } | null;
};
export type UsersListQuery_usersQuery = {
  variables: UsersListQuery_usersQuery$variables;
  response: UsersListQuery_usersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersListQuery_usersQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": "allUsersViews",
        "args": (v5/*: any*/),
        "concreteType": "UsersViewsConnection",
        "kind": "LinkedField",
        "name": "__UsersListQuery_allUsersViews_connection",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UsersViewsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UsersView",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v9/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserRow"
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UsersListQuery_usersQuery",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "UsersViewsConnection",
        "kind": "LinkedField",
        "name": "allUsersViews",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UsersViewsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UsersView",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountId",
                    "storageKey": null
                  },
                  {
                    "alias": "firstName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": "lastName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "surname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "login",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "first",
          "after",
          "orderBy",
          "offset"
        ],
        "handle": "connection",
        "key": "UsersListQuery_allUsersViews",
        "kind": "LinkedHandle",
        "name": "allUsersViews"
      }
    ]
  },
  "params": {
    "cacheID": "e836a9f974c07cc8cb9ed625dc02f8d2",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "allUsersViews"
          ]
        }
      ]
    },
    "name": "UsersListQuery_usersQuery",
    "operationKind": "query",
    "text": "query UsersListQuery_usersQuery(\n  $first: Int\n  $after: Cursor\n  $orderBy: [UsersViewsOrderBy!]\n  $offset: Int\n) {\n  id\n  allUsersViews(first: $first, after: $after, orderBy: $orderBy, offset: $offset) {\n    totalCount\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      startCursor\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        rowId\n        ...UserRow\n        __typename\n      }\n    }\n  }\n}\n\nfragment UserRow on UsersView {\n  id\n  accountId\n  rowId\n  firstName: name\n  lastName: surname\n  email\n  login\n}\n"
  }
};
})();

(node as any).hash = "a0f512851451664d76d085bd59461408";

export default node;
