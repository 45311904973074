import {useCallback, useState, useTransition} from "react";
import {Box} from "@chakra-ui/react";
import type {UsersListProps} from "../users/UsersList";
import {UsersList} from "../users/UsersList";
import {UserModal} from "../users/UserModal";
import {Route, Routes, useNavigate} from "react-router-dom";
import {RecordToolbox} from "../common/RecordToolbox";
import {AppQuery$data} from "../../__generated__/AppQuery.graphql";
import {useQueries} from "../QueriesProvider";
import {UsersListFilters, UsersListState} from "../users/useUsersListState";
import {ConfirmDeleteUserModal} from "../users/ConfirmDeleteUserModal";
import {RecordsListState} from "../../types/types";
import {UsersListQuery_usersQuery} from "../users/__generated__/UsersListQuery_usersQuery.graphql";


/*export const defaultUsersListState: UsersListState = {
  filters: {
    offset: 0,
    orderBy: ["ID_ASC"],
    first: 10,
    after: undefined
  },
  paginator: {
    currentPage: 1,
  }
}*/

export const defaultUsersListState: RecordsListState<UsersListQuery_usersQuery> = {
  filters: {
    offset: 0,
    orderBy: ["ID_ASC"],
    first: 10,
    after: undefined
  },
  paginator: {
    currentPage: 1,
  }
}

// TODO: wyciągnąć do pliku z typami
export interface PageProps {
  layout?: AppQuery$data
}

export interface UsersPageProps extends PageProps {
}

export const UsersPage = ({layout}: UsersPageProps) => {

  console.log("UsersPage: render");
  const navigate = useNavigate();

  const {loaders: {users: {loadUsers}}} = useQueries();

  const [isInTransition, transition] = useTransition();
  
  const [listState, setListState] = useState<UsersListState>(defaultUsersListState);

  console.log("UsersPage:listState", listState);

  const onPageChange = useCallback<Exclude<UsersListProps["onPageChange"], undefined>>(
    (page, offset, recordsPerPage) => {
      
      const newFilters: UsersListFilters = {
        ...listState.filters,
        offset: (page - 1) * recordsPerPage,
        first: recordsPerPage,
      };

      setListState({
        paginator: {
          currentPage: page
        },
        filters: newFilters
      });
      
      transition(() => {
        loadUsers(newFilters);
      });

    }, [loadUsers, listState]);

  // Czy nie lepiej zrobić useEffect jak się zmienią filtry?
  // Czy to spowodude, że będzie sie póxniej ładowało?
  const onSort = useCallback<Exclude<UsersListProps["onSort"], undefined>>(
    (orderBy) => {

      const newFilters = {...listState.filters, orderBy: orderBy}

      //console.log("onSort new filters", newFilters);
      setListState({...listState, filters: newFilters});

      transition(() => {
        loadUsers(newFilters);
      });

    },
    [loadUsers, listState],
  );
  
  const onCrudModalClose = useCallback(() => {
    navigate("/users");
  },[navigate]);
  
  return (
    <Box>
      <Routes>
        <Route path="edit/:id"
               element={<UserModal filters={listState.filters} isOpen={true} onClose={onCrudModalClose}/>}/>
        <Route path="edit"
               element={<UserModal filters={listState.filters} isOpen={true} onClose={onCrudModalClose}/>}/>
        <Route path="delete/:id" 
               element={<ConfirmDeleteUserModal filters={listState.filters} onClose={onCrudModalClose}/>} />
      </Routes>
      
      <RecordToolbox onAddClick={() => navigate("/users/edit")} addVisible={true} searchVisible={false}/>
      
      <Box bgColor="white" >

        <UsersList
          onPageChange={onPageChange}
          onSort={onSort}
          isLoading={isInTransition}
          onNextClick={(cursor => loadUsers({after: cursor, first: listState.filters.first}))}/>

      </Box>

      <Box>
        {/*<UserItem userRef={user} />*/}
      </Box>

    </Box>
  );
}

export default UsersPage;