import {useEffect, useRef, useState} from "react";
import {useDebouncedCallback} from "use-debounce";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useFormikContext} from "formik";
import {graphql} from "babel-plugin-relay/macro";
import {UserData} from "./UserForm";
import {fetchQuery, useRelayEnvironment} from "react-relay/hooks";
import {useDebouncedLoginValidationQuery} from "./__generated__/useDebouncedLoginValidationQuery.graphql";

export const useDebouncedLoginValidation = (name: string) => {
  
  const {t} = useTranslation("validation");
  const prevLogin = useRef("");
  const lastResult = useRef(true);
  const environment = useRelayEnvironment();
  
  const [isChecking, setIsChecking] = useState(false);

  // Do tej zmiennej zapiasuję ilość oczekujących requestów strzałów do serwera
  // dopóki jakieś są, to utrzymuję kręciołka mówiącego o sprawdzaniu danych
  const pendingRequests = useRef(0);

  const {initialValues: {login: initialLogin}} = useFormikContext<UserData>();

  const debouncedLoginValidation = useDebouncedCallback((value, context, initialLogin, resolve) => {

    setIsChecking(true);

    // Initial login, to login, jak był przekazany do formularza
    // Jeżeli jest to edycja, to jeżeli aktualny login jest taki sam jak initial, to nie sprawdzamy
    // Bo jest to cały czas ten sam user z tym samym loginem
    if (value === initialLogin) {
      pendingRequests.current = 0;
      resolve(true);
      return;
    }

    if (value === prevLogin.current) {
      resolve(lastResult.current);
      return;
    }

    if (value !== prevLogin.current) {

      // Zabezpieczenie przed ujemnymi wartościami, żeby się póxniej liczniki zgadzały po zerowaniu jeżeli jest identyczne z initialValue
      pendingRequests.current = pendingRequests.current < 0 ? 1 : pendingRequests.current += 1;

      prevLogin.current = value;

      setIsChecking(true);
      fetchQuery<useDebouncedLoginValidationQuery>(environment, graphql`
                query useDebouncedLoginValidationQuery($login:String) {
                  allAccounts(condition: {login: $login}) {
                    totalCount @required(action: THROW)
                  }
                }`,
        {
          login: value
        },
        {
          fetchPolicy: "network-only"
        }
      ).toPromise().then((result) => {

        if (result?.allAccounts) {
          const ret = result.allAccounts.totalCount === 0;
          lastResult.current = ret;
          pendingRequests.current -= 1;
          resolve(ret);
          return;
        } else {
          resolve(true);
        }
      });
      
    } else {
      resolve(true);
      return;
    }

  }, 250);

  const loginSchema = Yup.string().test(
    "uniqueLogin",
    t("loginTaken"),
    (value, context) => new Promise(resolve => {

      // Najpierw sprawdzam synchronicznie
      return Yup.string()./*email(t("emailNotValid")).*/required(t("fieldRequired")).validate(value).then(() => {

        return debouncedLoginValidation(value, context, initialLogin, resolve);

      }).catch(err => resolve(err));
    }));

  const validate = (login: string) => {

    return loginSchema.validate(login).then(() => {

      if (pendingRequests.current <= 0) {
        setIsChecking(false);
      }
      return undefined;
    }).catch((err) => {
      if (pendingRequests.current <= 0) {
        setIsChecking(false);
      }
      return err.errors[0];
    })
  };

  // Validacja musi być skasowana przed odmontowaniem, żeby react nie walił błędem o ustawianiu stanu na odmontowanym komponencie
  useEffect(() => {
    return () => {
      debouncedLoginValidation.cancel();
    };
  }, [debouncedLoginValidation]);

  return {isChecking, validate};
}