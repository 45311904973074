import {Select, SelectProps} from "@chakra-ui/react";

export interface RecordsPerPageProps extends SelectProps {}

const values = [5,10,20,50,100];

export const RecordsPerPage = ({...props}:RecordsPerPageProps) => {
  return(
    <Select {...props}>
      {values.map( v => <option key={v} value={v}>{v}</option>)}
    </Select>
  );
}