import {PreloadedQuery, usePreloadedQuery} from "react-relay/hooks";
import ChatHistoryModalChatHistoryQueryGraphql, {
  ChatHistoryModalChatHistoryQuery
} from "./__generated__/ChatHistoryModalChatHistoryQuery.graphql";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import type {IStorage, MessageContent} from "@chatscope/use-chat";
import {
  BasicStorage,
  ChatMessage,
  ChatProvider,
  Conversation,
  MessageContentType,
  MessageDirection,
  MessageStatus
} from "@chatscope/use-chat";
import {ChatHistoryAdapter} from "./ChatHistoryAdapter";
import {nanoid} from "nanoid";
import {useEffect, useMemo, useState} from "react";
import {ChatWindow} from "./ChatWindow";
import parseIso from "date-fns/parseISO";
import format from "date-fns/format";
import {DATETIME_FORMAT, TIME_FORMAT_MINUTES} from "../../utils/formats";
import {Box, Flex, Table, Tbody, Td, Th, Tr} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

interface ChatHistoryProps {
  chatId: string;
  historyRef: PreloadedQuery<ChatHistoryModalChatHistoryQuery>;
}

const groupIdGenerator = () => nanoid();

const externalStorage = new BasicStorage({
  groupIdGenerator,
});

const serviceFactory = (storage: IStorage, updateState: () => void) => new ChatHistoryAdapter(storage, updateState);

export const ChatHistory = ({historyRef, chatId}: ChatHistoryProps) => {

  const {t} = useTranslation("chatHistoryModal");

  const historyData = usePreloadedQuery(ChatHistoryModalChatHistoryQueryGraphql, historyRef);

  // Because use-chat not exposes method to update state, the component don't known that it should re-render
  // so we need to tell it to do it
  const [hasData, setHasData] = useState(false);
  
  // Add messages to the storage
  useEffect(() => {
    externalStorage.addConversation(new Conversation({
      id: chatId,
    }));

    historyData.allChatMessageLogs?.nodes.forEach(m => {

      if (m) {
        const agentSenderId = m.accountByAccountId?.id;
        const customerSenderId = m.widgetContactByContactId?.id;

        const newMessage: ChatMessage<MessageContentType.TextHtml> & { time?: string } = new ChatMessage<MessageContentType.TextHtml>({
          id: m.id,
          content: m.content as unknown as MessageContent<MessageContentType.TextHtml>,
          direction: customerSenderId ? MessageDirection.Incoming : MessageDirection.Outgoing,
          contentType: MessageContentType.TextHtml,
          senderId: agentSenderId ?? customerSenderId ?? "SYSTEM",
          status: MessageStatus.Seen,
        });

        newMessage.time = m.messageTime ? format(parseIso(m.messageTime), TIME_FORMAT_MINUTES) : "";

        externalStorage.addMessage(newMessage, chatId);
      }

    });

    externalStorage.setActiveConversation(chatId);
    setHasData(true);

    return () => {
      externalStorage.removeConversation(chatId);
    }
  }, [historyData, chatId]);


  const advisor = useMemo(() => {
    
    // Find advisor
    // Shitty but works
    const node = historyData.allChatMessageLogs?.nodes.find( m => {
      const agentSenderId = m?.accountByAccountId?.id;
      if ( agentSenderId ) {
        return true;
      }
    });
    
    if ( node  ) {
      const user = node.accountByAccountId?.userByAccountId; 
      return `${user?.firstName} ${user?.lastName}`;
    }
    
    return "";
    
  },[historyData]);

  const contact = useMemo(() => {

    // Find advisor
    // Shitty but works
    const node = historyData.allChatMessageLogs?.nodes.find( m => {
      const customerSenderId = m?.widgetContactByContactId?.id;
      if ( customerSenderId ) {
        return true;
      }
    });

    if ( node  ) {
      return node.widgetContactByContactId?.name ?? "";
    }

    return "";

  },[historyData]);
  
  
  return (
    <ChatProvider serviceFactory={serviceFactory} storage={externalStorage}>
      {externalStorage.getState().conversations.length > 0 ? <Flex height="100%" direction="column" overflow="hidden" wrap="nowrap" position="relative">
        <Box borderWidth={1} borderRadius={6} shadow="md" mb={6} p={3} mx={2}>
          <Table variant="properties" size="sm">
            <Tbody>
              <Tr>
                <Th>{t("advisor")}</Th>
                <Td>{advisor}</Td>
              </Tr>
              <Tr>
                <Th>{t("contact")}</Th>
                <Td>{contact}</Td>
              </Tr>
              <Tr>
                <Th>{t("conversationStartTime")}</Th>
                <Td>{historyData.chatByRowId?.createTime ? format(parseIso(historyData.chatByRowId?.createTime), DATETIME_FORMAT): ""}</Td>
              </Tr>
              <Tr>
                <Th>{t("conversationEndTime")}</Th>
                <Td>{historyData.chatByRowId?.endTime ? format(parseIso(historyData.chatByRowId?.endTime), DATETIME_FORMAT) : ""}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box position="relative" overflow="hidden" flexGrow="1">
          <ChatWindow/>
        </Box>
      </Flex> : undefined}
    </ChatProvider>
  );
}
