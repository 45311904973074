import {PreloadedQuery, usePreloadedQuery} from "react-relay/hooks";
import {UserRow} from "./UserRow";
import UsersListQuery_usersQueryGraphql, {
  UsersListQuery_usersQuery
} from "./__generated__/UsersListQuery_usersQuery.graphql";
import {Suspense, useEffect} from "react";

export interface UsersListBodyProps  {
  usersRef: PreloadedQuery<UsersListQuery_usersQuery>;
  onTotalChange: (total:number) => void;
}

export const UsersListBody = ({usersRef, onTotalChange}:UsersListBodyProps) => {
  
  const data = usePreloadedQuery<UsersListQuery_usersQuery>(UsersListQuery_usersQueryGraphql, usersRef);
  
  useEffect(() => {
    const total = data?.allUsersViews?.totalCount;
    if ( total ) {
      onTotalChange(total);  
    }
  }, [data?.allUsersViews?.totalCount, onTotalChange]);
  
  return (
    <Suspense fallback="*****************************************************">
      {data?.allUsersViews?.edges?.map(         
          (user, i) => <UserRow key={user?.node?.rowId} user={user?.node} idx={i} />
        ) ?? null}
    </Suspense>
  );
  
}