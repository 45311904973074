import React, {ReactNode} from "react";
import type {ChangeEvent, ClipboardEvent, InputHTMLAttributes} from "react";
import {useField} from "formik";
import {FormikFieldErrorMessage} from "./FormikFieldErrorMessage";
import {
  FormControl,
  FormControlProps, FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {PasswordField} from "../forms/PasswordField";

export type FormikFormGroupTextFieldProps = FormControlProps & Pick<InputHTMLAttributes<"password" | "text">, "autoComplete"> & {
  name:string;
  readonly?: boolean;
  type?: "text" | "password" | "email";
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (evt: ClipboardEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  showPasswordHint?: string;
  hidePasswordHint?: string;
  helperText?: ReactNode;
  eyeTabIndex?: number;
}


export const FormikFormGroupTextField = ({ name, label, placeholder, readonly, type = "text",
                                           helperText,
                                           onChange,
                                           onPaste, isRequired, showPasswordHint, hidePasswordHint, autoComplete, eyeTabIndex, ...props }:FormikFormGroupTextFieldProps) => {

  const [ field , meta ] = useField(name);

  const {error, touched} = meta;
  const { onChange: onFieldChange } = field;
  
  return (  
    <FormControl isInvalid={error && touched ? true : false} isRequired={isRequired} mt={3} {...props}>
      <FormLabel htmlFor={name} userSelect="none">{label}</FormLabel>
      {type !== "password" ? <Input {...field} id={name} placeholder={placeholder} type={type} readOnly={readonly} autoComplete={autoComplete}
                                    onChange={evt => {
                                      if  ( onChange ) {
                                        onChange(evt);
                                      }
                                      onFieldChange(evt);
                                    }}
                                    onPaste={onPaste} isRequired={false} /> : 
        <PasswordField {...field} placeholder={placeholder}
                       isReadOnly={readonly}
                       onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                        if  ( onChange ) {
                          onChange(evt);
                        }
                        onFieldChange(evt);
                       }}
                       onPaste={onPaste}
                       showHint={showPasswordHint}
                       hideHint={hidePasswordHint}
                       eyeTabIndex={eyeTabIndex}
        />}
      {(!error || !touched) && <FormHelperText minHeight={5}>{helperText}</FormHelperText>}
      <FormikFieldErrorMessage name={name} />
    </FormControl>
    
  );
};
