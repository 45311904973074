import React from "react";
import {ErrorMessage} from "formik";
import {FormErrorMessage} from "@chakra-ui/react";

interface FormikFieldErrorMessageProps {
    name:string;
    className?:string;
}

export const FormikFieldErrorMessage = ({name, className}:FormikFieldErrorMessageProps) => {
  
    return (
        <ErrorMessage name={name}>
          {msg => <FormErrorMessage className={className} minHeight={5}>{msg}</FormErrorMessage>}
        </ErrorMessage>
    );
};
