import {ConfirmDeleteModal, ConfirmDeleteModalProps} from "../common/modals";
import {useCallback} from "react";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useUserCrud} from "./useUserCrud";
import {UsersListFilters} from "./useUsersListState";
import {useToast} from "@chakra-ui/react";
import {useQueries} from "../QueriesProvider";

export interface ConfirmDeleteUserModalProps extends Pick<ConfirmDeleteModalProps, "onClose"> {
  filters: UsersListFilters;
}

export const ConfirmDeleteUserModal = ({onClose, filters}: ConfirmDeleteUserModalProps) => {

  const {t} = useTranslation("deleteUserModal");
  
  const navigate = useNavigate();
  const toast = useToast()
  const location = useLocation();
  
  const {loaders:{users:{loadUsers}}} = useQueries();
  
  const { onDelete, isSubmitting } = useUserCrud(() => {
    toast({
      title: t("userDeleted"),
      position: "top",
      status: "success",
      duration: 4000,
      isClosable: true
    });
    loadUsers(filters, {fetchPolicy: "network-only"});
    navigate("/users");
  });
  
  const onConfirm = useCallback(() => {
    const userMatch = matchPath( "/users/delete/:id", location.pathname);
    if (userMatch) {
      const id = userMatch.params.id;
      const idNum = Number(id); 
      if ( idNum  ) {
        onDelete(idNum);
      }
    }
  },[location, onDelete]);
  
  return (
    <ConfirmDeleteModal onConfirm={onConfirm} isOpen={true} onClose={onClose}
                        header={t("header")}
                        body={t("content")}
                        isSubmitting={isSubmitting}
    />
  );
  
}