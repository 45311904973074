/**
 * @generated SignedSource<<d80ea4a47f7deb488209dc0d05cbdb1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChatHistoryModalChatHistoryQuery$variables = {
  chatId: any;
};
export type ChatHistoryModalChatHistoryQuery$data = {
  readonly allChatMessageLogs: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly widgetContactByContactId: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly accountByAccountId: {
        readonly id: string;
        readonly userByAccountId: {
          readonly firstName: string;
          readonly lastName: string;
        } | null;
      } | null;
      readonly content: string;
      readonly messageTime: any;
    } | null>;
  } | null;
  readonly chatByRowId: {
    readonly createTime: any;
    readonly endTime: any | null;
  } | null;
};
export type ChatHistoryModalChatHistoryQuery = {
  variables: ChatHistoryModalChatHistoryQuery$variables;
  response: ChatHistoryModalChatHistoryQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "chatId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "chatId",
        "variableName": "chatId"
      }
    ],
    "kind": "ObjectValue",
    "name": "condition"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "WidgetContact",
  "kind": "LinkedField",
  "name": "widgetContactByContactId",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageTime",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "chatId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createTime",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatHistoryModalChatHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChatMessageLogsConnection",
        "kind": "LinkedField",
        "name": "allChatMessageLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatMessageLog",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "accountByAccountId",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "userByAccountId",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Chat",
        "kind": "LinkedField",
        "name": "chatByRowId",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChatHistoryModalChatHistoryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChatMessageLogsConnection",
        "kind": "LinkedField",
        "name": "allChatMessageLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ChatMessageLog",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "accountByAccountId",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "userByAccountId",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Chat",
        "kind": "LinkedField",
        "name": "chatByRowId",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5c962728ab17d8c38b9ddcc823a985f",
    "id": null,
    "metadata": {},
    "name": "ChatHistoryModalChatHistoryQuery",
    "operationKind": "query",
    "text": "query ChatHistoryModalChatHistoryQuery(\n  $chatId: UUID!\n) {\n  allChatMessageLogs(condition: {chatId: $chatId}) {\n    nodes {\n      id\n      widgetContactByContactId {\n        id\n        name\n      }\n      accountByAccountId {\n        id\n        userByAccountId {\n          firstName\n          lastName\n          id\n        }\n      }\n      content\n      messageTime\n    }\n  }\n  chatByRowId(rowId: $chatId) {\n    createTime\n    endTime\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "82c7a552bbc37c897cc97bc7c6598e26";

export default node;
