import {PreloadedQuery, usePreloadedQuery} from "react-relay/hooks";
import {ChatRow} from "./ChatRow";
import {Suspense, useEffect} from "react";
import ChatsListQuery_chatsQueryGraphql, {ChatsListQuery_chatsQuery} from "./__generated__/ChatsListQuery_chatsQuery.graphql";

export interface ChatsListBodyProps  {
  chatsRef: PreloadedQuery<ChatsListQuery_chatsQuery>;
  onTotalChange: (total:number) => void;
}

export const ChatsListBody = ({chatsRef, onTotalChange}:ChatsListBodyProps) => {
  
  const data = usePreloadedQuery<ChatsListQuery_chatsQuery>(ChatsListQuery_chatsQueryGraphql, chatsRef);
  
  useEffect(() => {
    const total = data?.allChatsViews?.totalCount;
    if ( total ) {
      onTotalChange(total);  
    }
  }, [data?.allChatsViews?.totalCount, onTotalChange]);
  
  return (
    <Suspense fallback="">
      {data?.allChatsViews?.edges?.map(         
          (chat, i) => <ChatRow key={chat?.node?.rowId} chat={chat?.node} idx={i} />
        ) ?? null}
    </Suspense>
  );
  
}