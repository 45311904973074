import React from "react";
import {
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter, Skeleton,
} from "@chakra-ui/react";
import type {AlertDialogProps} from "@chakra-ui/react";
import {ReactNode, useRef} from "react";
import {CancelButton, DeleteButton} from "../buttons";
import {Cover} from "../Cover";
import {LightSkeleton} from "../LightSkeleton";

export interface ConfirmDeleteModalProps extends Omit<AlertDialogProps, "leastDestructiveRef" | "children"> {
  onConfirm: () => void;
  isSubmitting?: boolean;
  header?: ReactNode;
  body?: ReactNode;
}

export const HeaderFallback = React.memo(() => <LightSkeleton height="27px"/>);

export const ConfirmDeleteModal = ({onClose, onConfirm, isSubmitting, header, body, ...props}:ConfirmDeleteModalProps) => {

  const cancelRef = useRef<HTMLButtonElement | null>(null);
  
  return (
    <AlertDialog onClose={onClose} {...props} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {header}
          </AlertDialogHeader>

          <AlertDialogBody>
            {body}
            {isSubmitting && <Cover backgroundColor="white" />}
          </AlertDialogBody>

          <AlertDialogFooter>
            <CancelButton innerRef={cancelRef} onClick={onClose} disabled={isSubmitting} />
            <DeleteButton ml={3} onClick={onConfirm} disabled={isSubmitting}/>             
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}