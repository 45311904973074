/**
 * @generated SignedSource<<f02345519cbd3a948aff8dc52431f067>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserInput = {
  clientMutationId?: string | null;
  id: string;
  userPatch: UserPatch;
};
export type UserPatch = {
  rowId?: number | null;
  accountId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  displayNameLocal?: string | null;
  displayNameRemote?: string | null;
  deleted?: boolean | null;
  email?: string | null;
  avatar?: string | null;
};
export type UpdateAccountInput = {
  clientMutationId?: string | null;
  id: string;
  accountPatch: AccountPatch;
};
export type AccountPatch = {
  rowId?: number | null;
  typeId?: number | null;
  organizationId?: number | null;
  securityRoleId?: number | null;
  login?: string | null;
  password?: string | null;
  active?: boolean | null;
  deleted?: boolean | null;
  statusCodeSetId?: number | null;
  defaultStatusCode?: number | null;
  maxConcurrentChats?: number | null;
};
export type UserUpdateMutation$variables = {
  input: UpdateUserInput;
  account: UpdateAccountInput;
};
export type UserUpdateMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly displayNameLocal: string;
      readonly displayNameRemote: string;
      readonly email: string;
    } | null;
  } | null;
  readonly updateAccount: {
    readonly account: {
      readonly login: string;
    } | null;
  } | null;
};
export type UserUpdateMutation = {
  variables: UserUpdateMutation$variables;
  response: UserUpdateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "input",
      "variableName": "input"
    }
  ],
  "concreteType": "UpdateUserPayload",
  "kind": "LinkedField",
  "name": "updateUser",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayNameLocal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayNameRemote",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "account"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "login",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserUpdateMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateAccountPayload",
        "kind": "LinkedField",
        "name": "updateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UserUpdateMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateAccountPayload",
        "kind": "LinkedField",
        "name": "updateAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ce8e69206fe8b286ecace9a210da42d",
    "id": null,
    "metadata": {},
    "name": "UserUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UserUpdateMutation(\n  $input: UpdateUserInput!\n  $account: UpdateAccountInput!\n) {\n  updateUser(input: $input) {\n    user {\n      id\n      firstName\n      lastName\n      displayNameLocal\n      displayNameRemote\n      email\n    }\n  }\n  updateAccount(input: $account) {\n    account {\n      login\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f30f2b44c1b3c995e7418a7801efb0f3";

export default node;
