import {SaveButton} from "./SaveButton";
import {CancelButton} from "./CancelButton";
import {ButtonProps} from "@chakra-ui/react";

export interface SaveCancelProps {
  onSave?: ButtonProps["onClick"];
  onCancel?: ButtonProps["onClick"];
  form?: ButtonProps["form"];
  saveDisabled?:boolean;
  cancelDisabled?:boolean;
  disabled?:boolean;
}
export const SaveCancel = ({onSave, onCancel, form, saveDisabled, cancelDisabled, disabled}:SaveCancelProps) => {
  return (
    <>
      <SaveButton onClick={onSave} form={form} disabled={saveDisabled || disabled} />
      <CancelButton onClick={onCancel} ml={4} disabled={cancelDisabled || disabled} />
    </>
  );
}