import {graphql} from "babel-plugin-relay/macro";

// CRUD operations for chat

export const HardDeleteChatMutation = graphql`
  mutation ChatDeleteHardMutation($rowId: UUID!) {
    deleteChatByRowId(input: {rowId: $rowId}) {
      deletedChatId
    }
  }
`;