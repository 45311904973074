import {Suspense} from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Skeleton, useToast
} from "@chakra-ui/react";
import type { ModalProps} from "@chakra-ui/react";
import {graphql} from "babel-plugin-relay/macro";
import {useMemo} from "react";
import {UserForm} from "./UserForm";
import {useNavigate, useParams} from "react-router-dom";
import {nanoid} from "nanoid";
import { QueryWrapper } from "./QueryWrapper";
import {useUserCrud} from "./useUserCrud";
import {UsersListFilters} from "./useUsersListState";
import {SaveCancel} from "../common/buttons";
import {useTranslation} from "react-i18next";
import {useQueries} from "../QueriesProvider";
import {Cover} from "../common/Cover";
import range from "lodash/range"

export interface UserModalProps extends Pick<ModalProps, "isOpen" | "onClose" > {
  filters: UsersListFilters;
}

export const UserQuery = graphql`
  query UserModalUserQuery($id: Int!) {
    userByRowId(rowId:$id) {
      id @required(action: THROW)
      firstName @required(action: THROW)
      lastName @required(action: THROW)
      displayNameLocal @required(action: THROW)
      displayNameRemote @required(action: THROW)
      accountByAccountId {
        id @required(action: THROW)
        rowId @required(action: THROW)
        login @required(action: THROW)
      }
    }
  }`;

export const UserModal = ({isOpen, onClose, filters }:UserModalProps) => {
  
  const {t} = useTranslation("userModal");
  
  const navigate = useNavigate();
  const toast = useToast()
  
  const {loaders:{users:{userQueryReference, loadUsers}}} = useQueries();
  
  // Get user id from url
  const { id }  = useParams();
  
  const formId = useMemo(() => nanoid(),[]);
  
  const { onSubmit, isSubmitting } = useUserCrud(() => {
    toast({
      title: id ? t("userDataSaved") : t("userCreated"),
      position: "top",
      status: "success",
      duration: 4000,
      isClosable: true  
    });
    loadUsers(filters, {fetchPolicy: "network-only"});
    navigate("/users");
  });
  
  const title = useMemo(() => typeof id === "undefined" ? t("userAdd") : t("userEdit"), [t, id]);
  
  const formSkeletonFallback = useMemo(() => 
    <Box>
      {range(0,10).map( i => {
      return <Skeleton key={i} height={(i - 2) % 3 === 0 ? "20px" : (i - 1) % 3 === 0 ? "40px" : "24px"}
                       mb={i % 3 === 0 && i !== 9 ? "8px" : 0}
                       mt={(i - 2) % 3 === 0 ? "8px" : i !== 0 && i % 3 === 0 ? "12px" : 0}
      ></Skeleton>;
    })}
    </Box>
    
  ,[]); 
  
  return (
    
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay /> 
      <ModalContent>
        <ModalHeader><Suspense>{title}</Suspense></ModalHeader>
        <ModalCloseButton/>
        <ModalBody position="relative">
          <Suspense fallback={formSkeletonFallback}>
            {typeof id === "undefined" ? <UserForm id={formId} onSubmit={onSubmit}/> : 
              userQueryReference ? <QueryWrapper userRef={userQueryReference} formId={formId} onSubmit={onSubmit} /> : null}
          </Suspense>
          {isSubmitting && <Cover backgroundColor="white" />}
        </ModalBody>
        <ModalFooter>
          <SaveCancel form={formId} onCancel={() => navigate("/users")} disabled={isSubmitting} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
  
}