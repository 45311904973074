import * as Yup from "yup";

export interface PasswordValidationI18n {
  fieldIsRequired:string;
  passwordTooShort:string;
  passwordMustContainsDigit:string;
  passwordMustContainCapitalLetter:string;
  passwordMustContainSmallLetter:string;
  passwordsNotMatch:string;
}

export const passwordSchema = (i18n: PasswordValidationI18n) => Yup.string().required(i18n.fieldIsRequired).min(8, i18n.passwordTooShort).matches(/\d/, i18n.passwordMustContainsDigit)
  .matches(/[A-Z]/, i18n.passwordMustContainCapitalLetter).matches(/[a-z]/, i18n.passwordMustContainSmallLetter);


export const passwordRepeatSchema = (i18n:PasswordValidationI18n, passwordFieldName:string) => Yup.string().required(i18n.fieldIsRequired).oneOf([Yup.ref(passwordFieldName)], i18n.passwordsNotMatch);
