import React from "react";
import type {ChangeEvent} from "react";
import {useField} from "formik";
import {FormikFieldErrorMessage} from "./FormikFieldErrorMessage";
import {
  FormControl,
  FormControlProps,
  FormLabel, HStack,
  Switch,
} from "@chakra-ui/react";
import type {SwitchProps} from "@chakra-ui/react";

export type FormikFormGroupSwitchFieldProps = FormControlProps & SwitchProps & {
  name:string;
  readonly?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
}


export const FormikFormGroupSwitchField = ({ name, label, placeholder, readonly,
                                           onChange, isRequired, ...props }:FormikFormGroupSwitchFieldProps) => {

  const [ field , meta ] = useField(name);

  const {error, touched} = meta;
  const { onChange: onFieldChange } = field;
  
  return (
    <FormControl isInvalid={error && touched ? true : false} isRequired={isRequired} mt={3} {...props}>
      <HStack>
      <Switch id={name}
              {...field}
              onChange={evt => {
                if  ( onChange ) {
                  onChange(evt);
                }
                onFieldChange(evt);
              }} mr={2}/>  
      <FormLabel htmlFor={name} userSelect="none" cursor="pointer">{label}</FormLabel>
      </HStack>
      <FormikFieldErrorMessage name={name} />
    </FormControl>
    
  );
};
