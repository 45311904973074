/**
 * @generated SignedSource<<011892e0d7b91a7b73411bfce1b457cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatRow$data = {
  readonly id: string;
  readonly accountId: number | null;
  readonly rowId: any;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly createTimeFormatted: string | null;
  readonly endTimeFormatted: string | null;
  readonly " $fragmentType": "ChatRow";
};
export type ChatRow$key = {
  readonly " $data"?: ChatRow$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatRow">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatRow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createTimeFormatted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTimeFormatted",
      "storageKey": null
    }
  ],
  "type": "ChatsView",
  "abstractKey": null
};

(node as any).hash = "0e5b7d2423fd76c945989e3431ded659";

export default node;
