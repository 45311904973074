import {graphql} from "babel-plugin-relay/macro";
import {NonNullableProps, RecordListProps} from "../../types/types";
import {VariablesOf} from "react-relay/hooks";
import {ChatsListQuery_chatsQuery} from "./__generated__/ChatsListQuery_chatsQuery.graphql";
import {useTranslation} from "react-i18next";
import {useQueries} from "../QueriesProvider";
import {useState, Suspense} from "react";
import {useListParams} from "../common/lists/useListParams";
import {Box, Table, TableContainer, Tbody, Th, Thead, Tr} from "@chakra-ui/react";
import {SortableTh} from "../common/lists/SortableTh";
import {Cover} from "../common/Cover";
import {Paginator} from "../common/Paginator/Paginator";
import {ChatsListBody} from "./ChatsListBody";

export const ChatsListChatsQuery = graphql`
  query ChatsListQuery_chatsQuery ($first:Int, $after: Cursor, $orderBy:[ChatsViewsOrderBy!], $offset: Int ) {
    id
    allChatsViews(first: $first, after: $after, orderBy: $orderBy, offset: $offset ) @connection(key: "ChatsListQuery_allChatsViews", filters:["first", "after", "orderBy", "offset"] ) {
      totalCount
      pageInfo
      {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor

      }
      edges {
        cursor
        node {
          id
          rowId
          ...ChatRow
        }
      }

    }
  }
`;

export type ChatsListFilters = NonNullableProps<VariablesOf<ChatsListQuery_chatsQuery>>;

export interface ChatsListProps extends RecordListProps<ChatsListFilters["orderBy"]>{

}

export const ChatsList = ({onNextClick, onPageChange, onSort, isLoading }:ChatsListProps) => {

  const {t} = useTranslation("chatsList");
  const {loaders: {chats: {chatsQueryReference}}} = useQueries();

  const [total,setTotal] = useState(0);
  const onTotalChange = (total:number) => setTotal(total);

  const {sortParams, sortByDirection} = useListParams<ChatsListFilters["orderBy"]>({
    onSort,
    defaultSortBy: "CREATE_TIME",
    defaultSortDirection: "DESC"}
  );
  
  return (
    <Box>
      <TableContainer position="relative" borderWidth={1} borderBottomWidth={0}>
        <Table variant="striped">
          <Thead>
            <Tr>
              {/*<SortableTh w="100px" fieldName="ID" header="Id" sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading} />*/}
              <SortableTh width="200px" whiteSpace="nowrap" fieldName="CREATE_TIME" header={t("startTime")} sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading}/>
              <SortableTh width="200px" whiteSpace="nowrap" fieldName="END_TIME" header={t("endTime")} sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading}/>
              <SortableTh fieldName="LAST_NAME" header={t("advisor")} sortParams={sortParams} onSort={sortByDirection} isDisabled={isLoading}/>
              <Th w="90px">&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Suspense fallback="">
              {chatsQueryReference && <ChatsListBody chatsRef={chatsQueryReference} onTotalChange={onTotalChange} />}
            </Suspense>
          </Tbody>
        </Table>
        {isLoading && <Cover backgroundColor="white" top="40px" />}
      </TableContainer>

      <Box mt={4} px={0} border={1}>
        <Paginator total={total} initialPage={1} initialPageSize={10} onPageChange={onPageChange} />
      </Box>
    </Box>
  );
  
};