import React, {lazy, Suspense} from "react";

import {Route, Routes} from "react-router-dom";
import {Center, Spinner} from "@chakra-ui/react";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const LayoutContainer = lazy(() => import("./LayoutContainer"));

export const RoutesContainer = () => {
  return (
    <Routes>
      <Route path="/login" element={<Suspense fallback={
        <Center minH="100vh" alignItems="start">
          <Spinner size="xl" color="blue.200" thickness="3px" emptyColor='gray.200' speed="0.6s" marginTop={48}/>
        </Center>}>
        <LoginPage />
      </Suspense>} />
      <Route path="/*" element={<LayoutContainer />} />
    </Routes>
  );
}