import {useField} from "formik";
import {useDebouncedLoginValidation} from "./useDebouncedLoginValidation";
import {
  Box,
  FormControl,
  FormControlProps, FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement, Spinner
} from "@chakra-ui/react";
import React, {InputHTMLAttributes} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FormikFieldErrorMessage, FormikFormGroupTextFieldProps} from "../common/formik";

export type LoginFieldProps = FormControlProps & Pick<InputHTMLAttributes<"text">, "autoComplete" | "readOnly"> & Pick<FormikFormGroupTextFieldProps,"helperText"> & {
  name:string;
}

export const LoginField = ({name, label, placeholder, helperText, readOnly,
                             onChange,
                             onPaste, isRequired, autoComplete, ...props }:LoginFieldProps) => {
  
    const {validate, isChecking } = useDebouncedLoginValidation(name);
    
    const [ field , meta, helpers ] = useField( { 
        name,
        validate
    });

    const { onBlur, value } = field;
    const { setTouched, setValue } = helpers;
    const { touched, error } = meta;
    
    return (
        <FormControl isInvalid={error && touched ? true : false} isRequired={isRequired} mt={3} {...props}>
          <FormLabel htmlFor={name} userSelect="none">{label}</FormLabel>
          <InputGroup size="md">
            <Input autoComplete="off"
                   placeholder={placeholder}
                    name={name}
                    onBlur={onBlur}
                    onChange={ (evt) => {
                        setTouched(true, true);
                        setValue(evt.target.value.toLowerCase());
                    }}                    
                    value={value}
                    isRequired={false}
            />
            <InputRightElement flexWrap="nowrap">
              {isChecking && <Spinner color="blue.200" thickness="3px" />}
              
              {!isChecking && touched && <Box color={error ? "red.500" : "green.500"}><FontAwesomeIcon icon={error ? faExclamationTriangle : faCheck} /></Box>}
            </InputRightElement>
          </InputGroup>
          {(!error || !touched) && <FormHelperText minHeight={5}>{helperText}</FormHelperText>}
          <FormikFieldErrorMessage name={name} />
    </FormControl>);
        
};
