import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesRight, faAnglesLeft} from "@fortawesome/free-solid-svg-icons";

import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  PaginationSeparator,
  usePagination
} from "@ajna/pagination";
import {Flex, FlexProps, IconButton} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {RecordsPerPage} from "./RecordsPerPage";
import {ChangeEvent, useCallback} from "react";

export interface PaginatorProps extends FlexProps {
  total?: number;
  initialPage: number;
  initialPageSize: number;
  onPageChange?: (page: number, offset: number, recordsPerPage: number) => void;
}

const calculateOffset = (page: number, pageSize: number) => page * pageSize - pageSize;

export const Paginator = ({total, initialPage, initialPageSize, onPageChange, ...props}: PaginatorProps) => {

  const {t} = useTranslation("paginator");

  const {
    currentPage,
    setCurrentPage,
    setPageSize,
    pagesCount,
    pages,
    pageSize,
    offset,
  } = usePagination({
    total: total ?? 0,
    limits: {
      outer: 2,
      inner: 2
    },
    initialState: {
      currentPage: initialPage,
      pageSize: initialPageSize
    },
  });


  const handlePageSizeChange = useCallback((evt: ChangeEvent<HTMLSelectElement>) => {
    
    const newPageSize = Number(evt.currentTarget.value);
    
    // User should see the same records as before page size change
    // Here we need to calculate new page
    const newPage = Math.floor(offset / newPageSize + 1);
    setPageSize(newPageSize);
    setCurrentPage(newPage);
    
    onPageChange?.(newPage, offset, newPageSize);
    
  }, [offset, onPageChange, setCurrentPage, setPageSize]);

  return (
    <Flex {...props} alignItems="center">
      
      <div>{t("showingXtoYofZ", {
        from: offset + 1,
        to: offset + 1 + pageSize,
        of: total
      })}</div>
      
      <Flex marginLeft="auto">
      {/* @ts-ignore */}
      <Pagination 
        onPageChange={(page) => {
          setCurrentPage(page);
          onPageChange?.(page, calculateOffset(page, pageSize), pageSize)
        }
        } currentPage={currentPage}
        pagesCount={pagesCount}
      >
        <PaginationContainer>
          <IconButton variant="outline"
                      mr={2}
                      pl={4}
                      pr={4}
                      icon={<FontAwesomeIcon icon={faAnglesLeft}/>} aria-label={t("goFirstPage")} title={t("goFirstPage")}
                      onClick={() => {
                        onPageChange?.(1, 0, pageSize);
                        setCurrentPage(1);
                      }}
          />
          <PaginationPrevious variant="outline"><FontAwesomeIcon icon={faAngleLeft}/></PaginationPrevious>
          <PaginationPageGroup mx={2}
                               separator={
                                 <PaginationSeparator
                                   bg="gray.100"
                                   fontSize="sm"
                                   w={7}
                                   jumpSize={2}
                                 />}
          >
            {pages.map((page: number) => (
              <PaginationPage
                key={`pagination_page_${page}`}
                page={page}
                variant="outline"
                px={3}
                _current={{
                  bg: "blue.50"
                }}
                _active={{
                  bg: "blue.50"
                }}
                _hover={{
                  bg: "blue.50"
                }}
              />
            ))}
          </PaginationPageGroup>
          <PaginationNext variant="outline"><FontAwesomeIcon icon={faAngleRight}/></PaginationNext>
          <IconButton
            variant="outline"
            ml={2}
            pl={4}
            pr={4}
            icon={<FontAwesomeIcon icon={faAnglesRight}/>} aria-label={t("goLastPage")} title={t("goLastPage")}
            onClick={() => {
              setCurrentPage(pagesCount);
              onPageChange?.(pagesCount, calculateOffset(pagesCount, pageSize), pageSize);
            }}
          />
        </PaginationContainer>
      </Pagination>
        <RecordsPerPage value={pageSize} onChange={handlePageSizeChange}
                        width="auto" marginLeft={4}
        />
      </Flex>
      
    </Flex>
  );

}