/**
 * @generated SignedSource<<3aa565e9cecab4c17039c9f72fca621e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateAccountInput = {
  clientMutationId?: string | null;
  account: AccountInput;
};
export type AccountInput = {
  rowId?: number | null;
  typeId: number;
  organizationId: number;
  securityRoleId: number;
  login: string;
  password?: string | null;
  active?: boolean | null;
  deleted?: boolean | null;
  statusCodeSetId: number;
  defaultStatusCode: number;
  maxConcurrentChats?: number | null;
};
export type UserCreateAccountMutation$variables = {
  data: CreateAccountInput;
};
export type UserCreateAccountMutation$data = {
  readonly createAccount: {
    readonly account: {
      readonly id: string;
      readonly rowId: number;
      readonly login: string;
    } | null;
  } | null;
};
export type UserCreateAccountMutation = {
  variables: UserCreateAccountMutation$variables;
  response: UserCreateAccountMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "data"
      }
    ],
    "concreteType": "CreateAccountPayload",
    "kind": "LinkedField",
    "name": "createAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "login",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserCreateAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserCreateAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3b33a4e511c971603b0fa420a2812e04",
    "id": null,
    "metadata": {},
    "name": "UserCreateAccountMutation",
    "operationKind": "mutation",
    "text": "mutation UserCreateAccountMutation(\n  $data: CreateAccountInput!\n) {\n  createAccount(input: $data) {\n    account {\n      id\n      rowId\n      login\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd8063b66469e009e6ac43d5d608fcc5";

export default node;
