import {graphql} from "babel-plugin-relay/macro";
import { useFragment} from "react-relay/hooks";
import {
    ChatRow$key
} from "./__generated__/ChatRow.graphql";
import {Td, Tr} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useQueries} from "../QueriesProvider";
import {IconDeleteButton} from "../common/buttons";
import {useCallback} from "react";
import type {MouseEvent} from "react";

const chatFragment = graphql`
    fragment ChatRow on ChatsView {
        id
        accountId
        rowId
        firstName 
        lastName
        createTimeFormatted
        endTimeFormatted
    }
`;

export interface ChatRowProps {
    chat: ChatRow$key | null;
    idx?: number;
}

export const ChatRow = ({chat}:ChatRowProps) => {
       
    const chatData = useFragment(chatFragment, chat);
    const navigate = useNavigate();
    const {routeCallbacks:{onChatRoute}} = useQueries();
    
    const onRowClick = useCallback(() => {
      const id = chatData?.rowId;
      if (id) {
        onChatRoute(id);
        navigate(`/chats/details/${id}`);
      }
    },[navigate, onChatRoute, chatData]);
    
    const onDeleteClick = useCallback((evt:MouseEvent<HTMLButtonElement>) => {
        // Prevent handling row click
        evt.stopPropagation();
        const id = chatData?.rowId;
        if (id) {
          navigate(`/chats/delete/${id}`);
        }
    },
    [navigate],
  );
  
    
    return (
        <Tr onClick={onRowClick}>
          {/*<Td>{chatData?.rowId}</Td>*/}
          <Td whiteSpace="nowrap">{chatData?.createTimeFormatted}</Td>
          <Td whiteSpace="nowrap">{chatData?.endTimeFormatted}</Td>
          <Td>{chatData?.firstName} {chatData?.lastName}</Td>
          <Td py={0} textAlign="center">
            <IconDeleteButton onClick={onDeleteClick}/>
          </Td>
        </Tr>
    );
    
}