import {Flex, Th} from "@chakra-ui/react";
import type {TableColumnHeaderProps} from "@chakra-ui/react";
import {SortArrow} from "./SortArrow";
import {ReactNode} from "react";
import {SortDirection} from "../../../types/types";


export interface SortParams {
  orderBy: string;
  direction: SortDirection;
}

export interface SortableThProps extends TableColumnHeaderProps {
  fieldName:string;
  header:ReactNode;
  sortParams:SortParams;
  isDisabled?:boolean;
  onSort?: (orderBy:string) => void;
}

export const SortableTh = ({fieldName, header, sortParams, onSort, isDisabled, ...rest}:SortableThProps ) => <Th {...rest} userSelect="none" onClick={() => {
  if ( !isDisabled ) {
    onSort?.(fieldName);
  }
}}>
  <Flex alignItems="center">{header} {sortParams.orderBy === fieldName && <SortArrow direction={sortParams.direction} isDisabled={isDisabled}/>}</Flex>
</Th>