import { Button } from "@chakra-ui/react";
import type { ButtonProps } from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const DeleteButton = ({className, ...props}:ButtonProps) => {
    
    const {t} = useTranslation("buttons");
    
    return (
        <Button
                type="submit"
                className={className}
                colorScheme="red"
                leftIcon={<FontAwesomeIcon icon={faTrash}/>}
                aria-label={t("delete")}
                {...props} >{t("delete")}</Button>
    );
};