import {graphql} from "babel-plugin-relay/macro";
import { useFragment} from "react-relay/hooks";
import {
    UserRow$key
} from "./__generated__/UserRow.graphql";
import {Td, Tr} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {useQueries} from "../QueriesProvider";
import {IconDeleteButton} from "../common/buttons";
import {useCallback} from "react";

const userFragment = graphql`
    fragment UserRow on UsersView {
        id
        accountId
        rowId
        firstName: name
        lastName: surname
        email
        login
    }
`;

export interface UserRowProps {
    user: UserRow$key | null;
    idx?: number;
}

export const UserRow = ({user}:UserRowProps) => {
       
    const userData = useFragment(userFragment, user);
    const navigate = useNavigate();
    const {routeCallbacks:{onUserRoute}} = useQueries();

    const onRowClick = useCallback(() => {
      console.log("Kilk row")
      const id = userData?.rowId;
      if (id) {
        onUserRoute(id);
        navigate(`/users/edit/${id}`);
      }
    },[navigate, onUserRoute, userData]);
    
    return (
        <Tr onClick={onRowClick}>
          <Td>{userData?.rowId}</Td>
          <Td whiteSpace="nowrap">{userData?.firstName}</Td>
          <Td whiteSpace="nowrap">{userData?.lastName}</Td>
          <Td whiteSpace="nowrap">{userData?.login}</Td>
          <Td py={0} textAlign="center">
            <IconDeleteButton onClick={(evt) => {
              evt.stopPropagation();
              const id = userData?.accountId;
              if (id) {
                navigate(`/users/delete/${id}`);
              }
            }}/>
          </Td>
        </Tr>
    );
    
}