import {useCallback, useState, useTransition, Suspense} from "react";
import {RecordsListState} from "../../types/types";
import {ChatsListQuery_chatsQuery} from "../chats/__generated__/ChatsListQuery_chatsQuery.graphql";
import {Route, Routes, useNavigate} from "react-router-dom";
import {useQueries} from "../QueriesProvider";
import {ChatsList, ChatsListFilters, ChatsListProps} from "../chats/ChatsList";
import {Box} from "@chakra-ui/react";
import {RecordToolbox} from "../common/RecordToolbox";
import {ChatHistoryModal} from "../chats/ChatHistoryModal";
import {ConfirmDeleteChatModal} from "../chats/ConfirmDeleteChatModal";

export type ChatsListState = RecordsListState<ChatsListQuery_chatsQuery>;

export const defaultChatsListState: ChatsListState = {
  filters: {
    offset: 0,
    orderBy: ["CREATE_TIME_DESC"],
    first: 10,
    after: undefined
  },
  paginator: {
    currentPage: 1,
  }
}

export const ChatsPage = () => {

  console.log("UsersPage: render");
  const navigate = useNavigate();

  const {loaders: {chats: {loadChats}}} = useQueries();

  const [isInTransition, transition] = useTransition();

  const [listState, setListState] = useState<ChatsListState>(defaultChatsListState);

  console.log("ChatsPage:listState", listState);

  // TODO: Chyba te obydwie metody mozna zdegeneralizować i wrzucić do hooka
  const onPageChange = useCallback<Exclude<ChatsListProps["onPageChange"], undefined>>(
    (page, offset, recordsPerPage) => {

      const newFilters: ChatsListFilters = {
        ...listState.filters,
        offset: (page - 1) * recordsPerPage,
        first: recordsPerPage,
      };

      setListState({
        paginator: {
          currentPage: page
        },
        filters: newFilters
      });

      transition(() => {
        loadChats(newFilters);
      });

    }, [loadChats, listState]);

  
  const onSort = useCallback<Exclude<ChatsListProps["onSort"], undefined>>(
    (orderBy) => {

      const newFilters = {...listState.filters, orderBy: orderBy}

      //console.log("onSort new filters", newFilters);
      setListState({...listState, filters: newFilters});

      transition(() => {
        loadChats(newFilters);
      });

    },
    [loadChats, listState],
  );
  
  return (
    <Box>
      <Routes>
        <Route path="details/:id"
               element={<ChatHistoryModal onClose={() => navigate("/chats") }/>}/>
        {/*<Route path="edit"
               element={<UserModal filters={listState.filters} isOpen={true} onClose={onCrudModalClose}/>}/>*/}
        <Route path="delete/:id"
               element={<ConfirmDeleteChatModal filters={listState.filters} onClose={() => navigate("/chats") }/>} />
      </Routes>
      
      <RecordToolbox onAddClick={() => navigate("/users/edit")} searchVisible={false}/>

      <Box bgColor="white" >

        <ChatsList
          onPageChange={onPageChange}
          onSort={onSort}
          isLoading={isInTransition}
          onNextClick={(cursor => loadChats({after: cursor, first: listState.filters.first}))}/>

      </Box>
      
    </Box>
  );
}

export default ChatsPage;